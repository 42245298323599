import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../store'
import { updateTypingUsers } from '../../../components/ChatComponent/Chat/chatsSlice'

export default function useCheckTypingUsers() {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(updateTypingUsers())
    }, 1000)

    return () => clearInterval(intervalId)
  })
}
