import protooClient from 'protoo-client'
import * as mediasoupClient from 'mediasoup-client'
import {
  setRoomDetails,
  setRoomState,
  setAllowedToJoin,
  setRoomActiveSpeaker,
  addChatMessage,
  setSpeakers,
  setSpeaker,
  setDisconnectDueToMultipleConnections,
} from './redux/roomSlice'
import {
  addProducer,
  removeProducer,
  setProducerPaused,
  setProducerResumed,
  setProducerScore,
  setProducerTrack,
} from './redux/producersSlice'
import { addDataProducer } from './redux/dataProducersSlice'
import {
  setMediaCapabilities,
  setCanChangeWebcam,
  setWebcamStatus,
  setShareInProgress,
  setRestartIceInProgress,
  setBokehEffectActive,
  setMicStatus,
  setBokehEffectInProgress,
  setSpeakerInProgress,
  MEDIA_DEVICE_STATUS,
  setWebcamActive,
  setMicActive,
} from './redux/meSlice'
import {
  addConsumer,
  removeConsumer,
  setConsumerCurrentLayers,
  setConsumerPaused,
  setConsumerResumed,
  setConsumerPreferredLayersState,
  setConsumerPriorityState,
  setConsumerScore,
} from './redux/consumersSlice'
import { setWebcam, setWebcams, setMic, setMics } from './redux/roomSlice'
import { addDataConsumer, removeDataConsumer } from './redux/dataConsumersSlice'
import { addPeer, removePeer } from './redux/roomSlice'
import { AppDispatch } from '../../store'
import EventEmitter from 'events'
import * as mpSelfieSegmentation from '@mediapipe/selfie_segmentation'
import * as bodySegmentation from '@tensorflow-models/body-segmentation'
// import * as tf from '@tensorflow/tfjs-core'
import { RefObject } from 'react'
import { RtpEncodingParameters } from 'mediasoup-client/lib/RtpParameters'
import logger from '../../utils/Logger'
import { t } from 'i18next'
import { setShowModalConnectionError } from './redux/meSlice'
import { ArgsProps } from 'antd/lib/notification'
import { useToastContext } from '../../components/Toast/ToastContext'
import { NavigateFunction, useNavigate } from 'react-router'

const ICE_SERVERS = JSON.parse(process.env.REACT_APP_ICE_SERVERS || '{}')
console.log('ICE_SERVERS', ICE_SERVERS)

type CameraResolution = 'qvga' | 'vga' | 'hd'

const VIDEO_CONSTRAINS = {
  qvga: { width: { ideal: 320 }, height: { ideal: 240 } },
  vga: { width: { ideal: 640 }, height: { ideal: 480 } },
  hd: { width: { ideal: 1280 }, height: { ideal: 720 } },
}

const PC_PROPRIETARY_CONSTRAINTS = {
  optional: [{ googDscp: true }],
}

// Used for simulcast webcam video.
const WEBCAM_SIMULCAST_ENCODINGS = [
  { scaleResolutionDownBy: 4, maxBitrate: 500000, scalabilityMode: 'L1T3' },
  { scaleResolutionDownBy: 2, maxBitrate: 1000000, scalabilityMode: 'L1T3' },
  { scaleResolutionDownBy: 1, maxBitrate: 5000000, scalabilityMode: 'L1T3' },
]

// Used for VP9 webcam video.
const WEBCAM_KSVC_ENCODINGS = [{ scalabilityMode: 'S3T3_KEY' }]

// Used for simulcast screen sharing.
const SCREEN_SHARING_SIMULCAST_ENCODINGS = [
  { dtx: true, maxBitrate: 1500000 },
  { dtx: true, maxBitrate: 6000000 },
]

// Used for VP9 screen sharing.
const SCREEN_SHARING_SVC_ENCODINGS = [{ scalabilityMode: 'S3T3', dtx: true }]

const BOKEH_VIDEO_FREQUENCY = 1000 / 60 // 60 fps

function getProtooUrl(roomId: string, accessToken: string) {
  return `${process.env.REACT_APP_PROTOO_BASE_URL}/media/ws/${roomId}/${accessToken}`
}

export default class RoomClient extends EventEmitter {
  _roomId: string
  _dispatch: AppDispatch
  _navigate: NavigateFunction
  _toastOpen: (props: { key?: React.Key } & Omit<ArgsProps, 'key'>) => void
  _closed = false
  _peerId: string // Peer id.

  // Custom mediasoup-client handler name
  // (to override default browser detection if desired).
  _handlerName: mediasoupClient.types.BuiltinHandlerName | undefined
  _useSimulcast: any // Whether simulcast should be used.
  _useSharingSimulcast: any // Whether simulcast should be used in desktop sharing.
  _forceTcp: boolean // Whether we want to force RTC over TCP.
  _produce: boolean // Whether we want to produce audio/video.
  _consume: boolean // Whether we should consume.
  _useDataChannel: boolean // Whether we want DataChannels.
  _forceH264: boolean // Force H264 codec for sending.
  _forceVP9: boolean // Force VP9 codec for sending.
  _svc: any
  _datachannel: any
  _nextDataChannelTestNumber = 0 // Next expected dataChannel test number.
  _protoo?: protooClient.Peer // protoo-client Peer instance.
  _mediasoupDevice?: mediasoupClient.Device // mediasoup-client Device instance.
  _sendTransport?: mediasoupClient.types.Transport // mediasoup Transport for sending.
  _recvTransport?: mediasoupClient.types.Transport // mediasoup Transport for receiving.
  _micProducer?: mediasoupClient.types.Producer // Local mic mediasoup Producer.
  _webcamProducer?: mediasoupClient.types.Producer // Local webcam mediasoup Producer.
  _shareProducer?: mediasoupClient.types.Producer // Local share mediasoup Producer.
  _chatDataProducer?: mediasoupClient.types.DataProducer // Local chat DataProducer.
  _consumers: Map<String, mediasoupClient.types.Consumer> = new Map() // mediasoup Consumers.
  _dataConsumers: Map<String, mediasoupClient.types.DataConsumer> = new Map() // mediasoup DataConsumers.
  _webcams: Map<String, MediaDeviceInfo> = new Map() // Map of webcam MediaDeviceInfos indexed by deviceId.
  _mics: Map<String, MediaDeviceInfo> = new Map() // Map of mics MediaDeviceInfos indexed by deviceId.
  _speakers: Map<String, MediaDeviceInfo> = new Map() // Map of speakers MediaDeviceInfos indexed by deviceId.
  // Local Webcam.
  _micId?: string
  _speaker?: MediaDeviceInfo
  _webcam: {
    id?: string
    resolution: CameraResolution
  } = {
    resolution: 'vga',
  }
  _localVideoTrack?: MediaStreamTrack
  _localBokehVideoTrack?: MediaStreamTrack
  _localAudioTrack?: MediaStreamTrack
  _hackAudioTrack?: MediaStreamTrack
  _outputCanvasRef?: RefObject<HTMLCanvasElement>
  _inputVideoRef?: RefObject<HTMLVideoElement>
  static _segmenter: Promise<bodySegmentation.BodySegmenter>
  _cameraWasActive: boolean = false
  _bokehActive: boolean = false

  constructor({
    roomId,
    dispatch,
    peerId,
    handlerName,
    useSimulcast,
    useSharingSimulcast,
    forceTcp,
    produce,
    consume,
    forceH264,
    forceVP9,
    svc,
    datachannel,
  }: any) {
    super()

    this._roomId = roomId
    this._dispatch = dispatch
    this._toastOpen = useToastContext().ToastOpen
    this._navigate = useNavigate()
    this._peerId = peerId
    this._forceTcp = forceTcp
    this._produce = produce
    this._consume = consume
    this._useDataChannel = datachannel
    this._forceH264 = Boolean(forceH264)
    this._forceVP9 = Boolean(forceVP9)
    this._handlerName = handlerName
    this._useSimulcast = useSimulcast
    this._useSharingSimulcast = useSharingSimulcast

    // Set custom SVC scalability mode.
    if (svc) {
      WEBCAM_KSVC_ENCODINGS[0].scalabilityMode = `${svc}_KEY`
      SCREEN_SHARING_SVC_ENCODINGS[0].scalabilityMode = svc
    }

    this._localVideoTrack = undefined
  }

  get localVideoTrack() {
    return this._localVideoTrack
  }

  close() {
    this._localAudioTrack?.stop()
    this._hackAudioTrack?.stop()
    this._localVideoTrack?.stop()

    if (this._closed) {
      return
    }
    this._closed = true

    // Close protoo Peer
    try {
      this._protoo?.close()
    } catch (e) {
      console.error(e)
    }

    // Close mediasoup Transports.
    this._sendTransport?.close()
    this._recvTransport?.close()
    this._micProducer?.close()
    this._webcamProducer?.close()
    this._dispatch(setRoomState('closed'))
  }

  async join(webcamActive: boolean, micActive: boolean) {
    await this._joinRoom(webcamActive, micActive)
  }

  async connect(accessToken: string) {
    const protooTransport = new protooClient.WebSocketTransport(
      getProtooUrl(this._roomId, accessToken),
    )

    this._protoo = new protooClient.Peer(protooTransport)

    this._dispatch(setRoomState('connecting'))

    this._protoo.on('open', async () => {
      this._dispatch(setRoomState('connected'))
    })

    this._protoo.on('failed', () => {
      console.error('WebSocket connection failed')
    })

    this._protoo.on('disconnected', () => {
      console.error('WebSocket disconnected')

      // Close mediasoup Transports.
      this._sendTransport?.close()
      this._sendTransport = undefined
      this._recvTransport?.close()
      this._recvTransport = undefined
      this._dispatch(setRoomState('closed'))
    })

    this._protoo.on('close', () => {
      if (this._closed) {
        return
      }

      this.close()
    })

    // eslint-disable-next-line no-unused-vars
    this._protoo.on('request', async (request, accept, reject) => {
      console.debug(
        'proto "request" event [method:%s, data:%o]',
        request.method,
        request.data,
      )

      switch (request.method) {
        case 'newConsumer': {
          if (!this._consume) {
            reject(403, 'I do not want to consume')

            break
          }

          const {
            peerId,
            producerId,
            id,
            kind,
            rtpParameters,
            type,
            appData,
            producerPaused,
          } = request.data

          try {
            if (!this._recvTransport) {
              return
            }

            const consumer = await this._recvTransport.consume({
              id,
              producerId,
              kind,
              rtpParameters,
              appData: { ...appData, peerId }, // Trick.
            })

            // Store in the map.
            this._consumers.set(consumer.id, consumer)

            consumer.on('transportclose', () => {
              this._consumers.delete(consumer.id)
            })

            const { spatialLayers, temporalLayers } =
              mediasoupClient.parseScalabilityMode(
                consumer.rtpParameters?.encodings &&
                  consumer.rtpParameters?.encodings[0].scalabilityMode,
              )

            this._dispatch(
              addConsumer({
                consumer: {
                  id: consumer.id,
                  type: type,
                  locallyPaused: false,
                  remotelyPaused: producerPaused,
                  rtpParameters: consumer.rtpParameters,
                  spatialLayers: spatialLayers,
                  temporalLayers: temporalLayers,
                  preferredSpatialLayer: spatialLayers - 1,
                  preferredTemporalLayer: temporalLayers - 1,
                  priority: 1,
                  codec: consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
                  track: consumer.track,
                  appData,
                },
                peerId,
              }),
            )

            // We are ready. Answer the protoo request so the server will
            // resume this Consumer (which was paused for now if video).
            accept()

            // If audio-only mode is enabled, pause it.
            /*
            if (consumer.kind === 'video' && store.getState().me.audioOnly)
              this._pauseConsumer(consumer)
            */
          } catch (e) {
            logger.error(
              `RoomClient(${this._roomId} ${this._peerId}) newConsumer failed ${String(
                e,
              )}`,
            )
            console.error('"newConsumer" request failed:%o', e)

            throw e
          }

          break
        }

        case 'newDataConsumer': {
          if (!this._consume) {
            reject(403, 'I do not want to data consume')

            break
          }

          if (!this._useDataChannel) {
            reject(403, 'I do not want DataChannels')

            break
          }

          const {
            peerId,
            dataProducerId,
            id,
            sctpStreamParameters,
            label,
            protocol,
            appData,
          } = request.data

          try {
            if (!this._recvTransport) {
              return
            }

            const dataConsumer = await this._recvTransport.consumeData({
              id,
              dataProducerId,
              sctpStreamParameters,
              label,
              protocol,
              appData: { ...appData, peerId }, // Trick.
            })

            // Store in the map.
            this._dataConsumers.set(dataConsumer.id, dataConsumer)

            dataConsumer.on('transportclose', () => {
              this._dataConsumers.delete(dataConsumer.id)
            })

            dataConsumer.on('open', () => {
              this._protoo?.request('retreiveMessages')

              console.debug('DataConsumer "open" event')
            })

            dataConsumer.on('close', () => {
              console.warn('DataConsumer "close" event')
              this._dataConsumers.delete(dataConsumer.id)
            })

            dataConsumer.on('error', (error) => {
              console.error('DataConsumer "error" event:%o', error)
            })

            dataConsumer.on('message', (message) => {
              console.debug(
                'DataConsumer "message" event [streamId:%d]',
                dataConsumer.sctpStreamParameters.streamId,
              )
              if (message instanceof ArrayBuffer) {
                const view = new DataView(message)
                const number = view.getUint32(0)

                if (number == Math.pow(2, 32) - 1) {
                  console.warn('dataChannelTest finished!')

                  this._nextDataChannelTestNumber = 0

                  return
                }

                if (number > this._nextDataChannelTestNumber) {
                  console.warn(
                    'dataChannelTest: %s packets missing',
                    number - this._nextDataChannelTestNumber,
                  )
                }

                this._nextDataChannelTestNumber = number + 1

                return
              } else if (typeof message !== 'string') {
                console.warn('ignoring DataConsumer "message" (not a string)')

                return
              }

              console.log(dataConsumer.label, message)

              if (dataConsumer.label === 'chat') {
                const parsed = JSON.parse(message)
                this._dispatch(
                  addChatMessage({
                    chat: parsed.chat,
                    email: this._peerId,
                    message: {
                      id: parsed._id,
                      authorId: parsed.authorId,
                      authorName: parsed.authorName,
                      timestamp: parsed.timestamp,
                      text: parsed.text,
                    },
                  }),
                )
              }
            })

            this._dispatch(
              addDataConsumer({
                dataConsumer: {
                  id: dataConsumer.id,
                  sctpStreamParameters: dataConsumer.sctpStreamParameters,
                  label: dataConsumer.label,
                  protocol: dataConsumer.protocol,
                },
                peerId,
              }),
            )

            // We are ready. Answer the protoo request.
            accept()
          } catch (e) {
            logger.error(
              `RoomClient(${this._roomId} ${
                this._peerId
              }) newDataConsumer failed ${String(e)}`,
            )
            console.error('"newDataConsumer" request failed:%o', e)

            throw e
          }

          break
        }
      }
    })

    this._protoo.on('notification', (notification) => {
      console.debug(
        'proto "notification" event [method:%s, data:%o]',
        notification.method,
        notification.data,
      )

      switch (notification.method) {
        case 'roomDetails':
          const {
            title,
            creator,
            invited,
            participants,
            startDate,
            duration,
            creatorNote,
            role,
            files,
            hasAccessFiles,
            waitingInvited,
            networkTroubleUsers,
            allowed,
            decrypt_key,
          } = notification.data
          this._dispatch(
            setRoomDetails({
              id: this._roomId,
              name: title,
              creator,
              participants,
              invited,
              startDate,
              duration,
              creatorNote,
              role,
              files,
              hasAccessFiles,
              waitingInvited,
              networkTroubleUsers,
              allowed,
              decrypt_key,
            }),
          )

          break

        case 'disconnectDueToMultipleConnections':
          this._dispatch(setDisconnectDueToMultipleConnections(true))
          break

        case 'producerScore':
          const { producerId, score } = notification.data
          this._dispatch(setProducerScore({ producerId, score }))
          break

        case 'newPeer': {
          const peer = notification.data

          this._dispatch(addPeer({ ...peer, consumers: [], dataConsumers: [] }))

          break
        }

        case 'peerClosed': {
          const { peerId } = notification.data

          this._dispatch(removePeer(peerId))

          break
        }

        case 'consumerClosed': {
          const { consumerId } = notification.data
          const consumer = this._consumers.get(consumerId)

          if (!consumer) break

          consumer.close()
          this._consumers.delete(consumerId)

          const { peerId } = consumer.appData as { peerId: string }

          this._dispatch(removeConsumer({ consumerId, peerId }))

          break
        }

        case 'consumerPaused': {
          const { consumerId } = notification.data
          const consumer = this._consumers.get(consumerId)

          if (!consumer) break

          consumer.pause()

          this._dispatch(setConsumerPaused({ consumerId, originator: 'remote' }))

          break
        }

        case 'consumerResumed': {
          const { consumerId } = notification.data
          const consumer = this._consumers.get(consumerId)

          if (!consumer) break

          consumer.resume()

          this._dispatch(setConsumerResumed({ consumerId, originator: 'remote' }))

          break
        }

        case 'consumerLayersChanged': {
          const { consumerId, spatialLayer, temporalLayer } = notification.data
          const consumer = this._consumers.get(consumerId)

          if (!consumer) break

          this._dispatch(
            setConsumerCurrentLayers({ consumerId, spatialLayer, temporalLayer }),
          )

          break
        }

        case 'consumerScore': {
          const { consumerId, score } = notification.data

          this._dispatch(setConsumerScore({ consumerId, score }))

          break
        }

        case 'dataConsumerClosed': {
          const { dataConsumerId } = notification.data
          const dataConsumer = this._dataConsumers.get(dataConsumerId)

          if (!dataConsumer) break

          dataConsumer.close()
          this._dataConsumers.delete(dataConsumerId)

          const { peerId } = dataConsumer.appData as { peerId: string }

          this._dispatch(removeDataConsumer({ dataConsumerId, peerId }))

          break
        }

        case 'activeSpeaker': {
          const { peerId } = notification.data

          this._dispatch(setRoomActiveSpeaker(peerId))

          break
        }

        case 'allowed': {
          this.emit('ALLOWED_TO_JOIN')
          this._dispatch(setAllowedToJoin('allowed'))
          break
        }

        case 'prohibited': {
          this.emit('DENIED_TO_JOIN')
          this._dispatch(setAllowedToJoin('prohibited'))
          break
        }

        default: {
          console.error('unknown protoo notification.method "%s"', notification.method)
        }
      }
    })
  }

  async enableMic() {
    console.debug('enableMic()')

    if (this._micProducer) return

    if (!this._mediasoupDevice?.canProduce('audio')) {
      console.error('enableMic() | cannot produce audio')
      return
    }

    let track

    try {
      this._dispatch(setMicStatus('ASKING_PERMISSIONS'))

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: { ideal: this._micId },
        },
      })
      track = stream.getAudioTracks()[0]
      this._localAudioTrack = stream.getAudioTracks()[0]

      if (!this._sendTransport) {
        return
      }

      this._micProducer = await this._sendTransport.produce({
        track,
        codecOptions: {
          opusStereo: true,
          opusDtx: true,
        },
        appData: { type: 'audio' },
        // NOTE: for testing codec selection.
        // codec : this._mediasoupDevice.rtpCapabilities.codecs
        //   .find((codec) => codec.mimeType.toLowerCase() === 'audio/pcma')
      })

      this._dispatch(
        addProducer({
          id: this._micProducer.id,
          type: 'audio',
          paused: this._micProducer.paused,
          track: this._micProducer.track,
          rtpParameters: this._micProducer.rtpParameters,
          codec: this._micProducer.rtpParameters.codecs[0].mimeType.split('/')[1],
        }),
      )

      this._micProducer.on('transportclose', () => {
        this._micProducer = undefined
      })

      this._micProducer.on('trackended', () => {
        console.error('Microphone disconnected!')

        this.disableMic().catch(() => {})
      })

      this._dispatch(setMicStatus('STARTED'))
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) enableMic failed ${String(e)}`,
      )
      console.error('enableMic() | failed:%o', e)

      this._dispatch(setMicStatus(this._getUserMediaErrorStatus(e)))
      if (track) {
        track.stop()
      }
    }
  }

  async disableMic() {
    console.debug('disableMic()')

    if (!this._micProducer) return

    this._micProducer.close()

    this._dispatch(removeProducer(this._micProducer.id))

    try {
      await this._protoo?.request('closeProducer', { producerId: this._micProducer.id })
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${
          this._peerId
        }) Error closing server-side mic Producer ${String(e)}`,
      )
      console.error(`Error closing server-side mic Producer: ${e}`)
    }

    this._micProducer = undefined
  }

  async muteMic() {
    console.debug('muteMic()')
    this._dispatch(setMicStatus('STOPPED'))

    if (!this._micProducer) {
      return
    }

    this._micProducer.pause()

    try {
      await this._protoo?.request('pauseProducer', { producerId: this._micProducer.id })
      this._dispatch(setProducerPaused(this._micProducer.id))
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) muteMic failed ${String(e)}`,
      )
      console.error('muteMic() failed: %o', e)
    }
  }

  async unmuteMic() {
    console.debug('unmuteMic()')

    if (!this._micProducer) {
      this.enableMic()
      return
    }

    this._micProducer.resume()

    try {
      await this._protoo?.request('resumeProducer', { producerId: this._micProducer.id })
      this._dispatch(setProducerResumed(this._micProducer.id))

      this._dispatch(setMicStatus('ASKING_PERMISSIONS'))
      await this._updateMics()
      this._dispatch(setMicStatus('STARTED'))
      this._dispatch(setMicActive(true))
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) unmuteMic failed ${String(e)}`,
      )
      console.error('unmuteMic() | failed: %o', e)
      this._dispatch(setMicStatus(this._getUserMediaErrorStatus(e)))
    }
  }

  async startWebcamDevice(bokehActive: boolean) {
    try {
      this._localVideoTrack?.stop()
      this._dispatch(setWebcamStatus('ASKING_PERMISSIONS'))
      const stream = await navigator.mediaDevices.getUserMedia({ video: true })
      this._localVideoTrack = stream.getVideoTracks()[0]
      this._webcam.id = this._localVideoTrack.getSettings().deviceId
      this._bokehActive = bokehActive
      await this._updateWebcams()
      this.toggleBokehEffect(this._bokehActive)
      this._dispatch(setWebcamStatus('STARTED'))
      this._dispatch(setWebcamActive(true))
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) startWebcamDevice failed ${String(
          e,
        )}`,
      )
      console.error(e)
      this._dispatch(setWebcamStatus(this._getUserMediaErrorStatus(e)))
    }
  }

  async stopWebcamDevice() {
    console.log(`stopWebcamDevice`, this._localVideoTrack)
    this._localVideoTrack?.stop()
    this._localVideoTrack = undefined
    this._dispatch(setWebcamStatus('STOPPED'))
    this._dispatch(setWebcamActive(false))
  }

  async changeWebcamDevice(deviceId: string) {
    this._localVideoTrack?.stop()

    try {
      this._dispatch(setWebcamStatus('ASKING_PERMISSIONS'))
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { ideal: deviceId } },
      })

      this._localVideoTrack = stream.getVideoTracks()[0]
      this._webcam.id = deviceId
      this._dispatch(setWebcam(deviceId))
      this._dispatch(setWebcamStatus('STARTED'))

      if (this._webcamProducer) {
        await this._webcamProducer.replaceTrack({ track: this._localVideoTrack })
        this._dispatch(
          setProducerTrack({
            producerId: this._webcamProducer.id,
            track: this._localVideoTrack,
          }),
        )
      }
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) changeWebcamDevice failed ${String(
          e,
        )}`,
      )
      console.error(e)
      this._dispatch(setWebcamStatus(this._getUserMediaErrorStatus(e)))
    }
  }

  _getUserMediaErrorStatus(e: any): MEDIA_DEVICE_STATUS {
    let status: MEDIA_DEVICE_STATUS = 'FAILED'

    if (e instanceof DOMException) {
      switch (e.name) {
        case 'NotAllowedError':
          status = 'NOT_ALLOWED'
          break
        case 'NotFoundError':
          status = 'NOT_FOUND'
          break
      }
    }

    return status
  }

  /**
   * Enable Webcam.
   * @returns
   */
  async enableWebcam(bokehActive: boolean) {
    console.debug('enableWebcam()')

    this._dispatch(setWebcamStatus('STOPPED'))

    if (this._webcamProducer) {
      return
    }

    if (!this._mediasoupDevice?.canProduce('video')) {
      console.error('Cannot produce video')
      return
    }

    try {
      this._localVideoTrack?.stop()
      const resolution = VIDEO_CONSTRAINS[this._webcam.resolution]
      const constraints: MediaStreamConstraints = {}
      if (this._webcam.id) {
        constraints.video = {
          deviceId: { ideal: this._webcam.id },
          width: resolution.width,
          height: resolution.height,
        }
      } else {
        constraints.video = {
          width: resolution.width,
          height: resolution.height,
        }
      }

      this._dispatch(setWebcamStatus('ASKING_PERMISSIONS'))
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      this._localVideoTrack = stream.getVideoTracks()[0]
      this._webcam.id = this._localVideoTrack.getSettings().deviceId
      await this._updateWebcams()

      let encodings
      let codec
      const codecOptions = { videoGoogleStartBitrate: 1000 }

      if (this._forceH264) {
        codec =
          this._mediasoupDevice.rtpCapabilities.codecs &&
          this._mediasoupDevice.rtpCapabilities.codecs.find(
            (c) => c.mimeType.toLowerCase() === 'video/h264',
          )

        if (!codec) {
          throw new Error('desired H264 codec+configuration is not supported')
        }
      } else if (this._forceVP9) {
        codec =
          this._mediasoupDevice.rtpCapabilities.codecs &&
          this._mediasoupDevice.rtpCapabilities.codecs.find(
            (c) => c.mimeType.toLowerCase() === 'video/vp9',
          )

        if (!codec) {
          throw new Error('desired VP9 codec+configuration is not supported')
        }
      }

      if (this._useSimulcast) {
        // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec =
          this._mediasoupDevice.rtpCapabilities.codecs &&
          this._mediasoupDevice.rtpCapabilities.codecs.find((c) => c.kind === 'video')

        if (
          (this._forceVP9 && codec) ||
          firstVideoCodec?.mimeType.toLowerCase() === 'video/vp9'
        ) {
          encodings = WEBCAM_KSVC_ENCODINGS
        } else {
          encodings = WEBCAM_SIMULCAST_ENCODINGS
        }
      }

      if (!this._sendTransport) {
        return
      }

      const type = this._getWebcamType(this._webcam.id || '')

      this._webcamProducer = await this._sendTransport.produce({
        track: this._localVideoTrack,
        encodings,
        codecOptions,
        codec,
        stopTracks: false,
        appData: {
          type,
        },
      })

      this._dispatch(
        addProducer({
          id: this._webcamProducer.id,
          deviceLabel: this._webcams.get(this._webcam.id || '')?.label || 'default',
          type,
          paused: this._webcamProducer.paused,
          track: this._webcamProducer.track,
          rtpParameters: this._webcamProducer.rtpParameters,
          codec: this._webcamProducer.rtpParameters.codecs[0].mimeType.split('/')[1],
        }),
      )

      this._webcamProducer.on('transportclose', () => {
        this._webcamProducer = undefined
      })

      this._webcamProducer.on('trackended', () => {
        this._toastOpen({
          message: 'Webcam disconnected!',
          type: 'error',
        })

        this.disableWebcam().catch(() => {})
      })

      this._dispatch(setWebcamActive(true))
      this._dispatch(setWebcamStatus('STARTED'))
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) enableWebcam failed ${String(e)}`,
      )
      console.error('enableWebcam() | failed:%o', e)

      this._dispatch(setWebcamStatus(this._getUserMediaErrorStatus(e)))
      this._localVideoTrack?.stop()
    }

    this._bokehActive = bokehActive
    this.toggleBokehEffect(this._bokehActive)
  }

  /**
   * Disable Webcam.
   * @returns
   */
  async disableWebcam() {
    console.debug('disableWebcam()')

    this._dispatch(setWebcamActive(false))
    this._dispatch(setWebcamStatus('STOPPED'))

    if (!this._webcamProducer) return

    this._webcamProducer.close()

    this._dispatch(removeProducer(this._webcamProducer.id))

    try {
      await this._protoo?.request('closeProducer', {
        producerId: this._webcamProducer.id,
      })
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${
          this._peerId
        }) Error closing server-side webcam Producer ${String(e)}`,
      )
      console.error(`Error closing server-side webcam Producer: ${e}`)
    }

    this._webcamProducer = undefined
  }

  /**
   * Change Webcam Resolution.
   */
  async changeWebcamResolution(resolution: CameraResolution) {
    this._dispatch(setWebcamStatus('UNKNOWN'))

    try {
      this._webcam.resolution = resolution

      console.debug(`changeWebcamResolution(${resolution})`)

      this._localVideoTrack && this._localVideoTrack.stop()
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { ideal: this._webcam.id },
          ...VIDEO_CONSTRAINS[this._webcam.resolution],
        },
      })

      this._localVideoTrack = stream.getVideoTracks()[0]

      if (this._webcamProducer) {
        await this._webcamProducer.replaceTrack({ track: this._localVideoTrack })
        this._dispatch(
          setProducerTrack({
            producerId: this._webcamProducer.id,
            track: this._localVideoTrack,
          }),
        )
      }

      this._dispatch(setWebcamStatus('STARTED'))
    } catch (e) {
      console.error('changeWebcamResolution() | failed: %o', e)
      if (e instanceof DOMException) {
        switch (e.name) {
          case 'NotAllowedError':
            this._dispatch(setWebcamStatus('NOT_ALLOWED'))
            break
          case 'NotFoundError':
            this._dispatch(setWebcamStatus('NOT_FOUND'))
            break
        }
      }
    }
  }

  /**
   * Enable Share.
   * @returns
   */
  async enableShare() {
    console.debug('enableShare()')

    if (this._shareProducer) {
      return
    }

    if (!this._mediasoupDevice?.canProduce('video')) {
      this._toastOpen({
        message: t('Cannot share screen.', { ns: 'room' }),
        type: 'error',
      })
      return
    }

    let track

    this._dispatch(setShareInProgress(true))

    try {
      console.debug('enableShare() | calling getUserMedia()')

      const stream = await (<any>navigator.mediaDevices).getDisplayMedia({
        audio: false,
        video: {
          displaySurface: 'monitor',
          logicalSurface: true,
          cursor: true,
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          frameRate: { max: 30 },
        },
      })

      // May mean cancelled (in some implementations).
      if (!stream) {
        this._dispatch(setShareInProgress(true))

        return
      }

      track = stream.getVideoTracks()[0]

      let encodings: RtpEncodingParameters[] = []
      let codec
      const codecOptions = {
        videoGoogleStartBitrate: 1000,
      }

      if (this._forceH264) {
        codec =
          this._mediasoupDevice.rtpCapabilities.codecs &&
          this._mediasoupDevice.rtpCapabilities.codecs.find(
            (c) => c.mimeType.toLowerCase() === 'video/h264',
          )

        if (!codec) {
          throw new Error('desired H264 codec+configuration is not supported')
        }
      } else if (this._forceVP9) {
        codec =
          this._mediasoupDevice.rtpCapabilities.codecs &&
          this._mediasoupDevice.rtpCapabilities.codecs.find(
            (c) => c.mimeType.toLowerCase() === 'video/vp9',
          )

        if (!codec) {
          throw new Error('desired VP9 codec+configuration is not supported')
        }
      }

      if (this._useSharingSimulcast) {
        // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec =
          this._mediasoupDevice.rtpCapabilities.codecs &&
          this._mediasoupDevice.rtpCapabilities.codecs.find((c) => c.kind === 'video')

        if (
          (this._forceVP9 && codec) ||
          firstVideoCodec?.mimeType.toLowerCase() === 'video/vp9'
        ) {
          encodings = SCREEN_SHARING_SVC_ENCODINGS
        } else {
          encodings = SCREEN_SHARING_SIMULCAST_ENCODINGS.map((encoding) => ({
            ...encoding,
            dtx: true,
          }))
        }
      }

      if (encodings.length === 0) {
        encodings = [{}]
      }

      encodings = encodings.map((e) => ({
        ...e,
        priority: 'high',
        networkPriority: 'high',
      }))

      if (this._sendTransport) {
        this._shareProducer = await this._sendTransport.produce({
          track,
          encodings,
          codecOptions,
          codec,
          appData: { type: 'share' },
        })

        this._dispatch(
          addProducer({
            id: this._shareProducer.id,
            type: 'share',
            paused: this._shareProducer.paused,
            track: this._shareProducer.track,
            rtpParameters: this._shareProducer.rtpParameters,
            codec: this._shareProducer.rtpParameters.codecs[0].mimeType.split('/')[1],
          }),
        )

        this._shareProducer.on('transportclose', () => {
          this._shareProducer = undefined
        })

        this._shareProducer.on('trackended', () => {
          this._toastOpen({
            message: t('Share disconnected.', { ns: 'room' }),
            type: 'info',
          })

          this.disableShare().catch(() => {})
        })
      }
    } catch (e: any) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) enableShare failed ${String(e)}`,
      )
      console.error('enableShare() | failed:%o', e)

      if (e.name !== 'NotAllowedError') {
        this._toastOpen({
          message: t('Error sharing screen.', { ns: 'room' }),
          type: 'error',
        })
      }

      if (track) {
        track.stop()
      }
    }

    this._dispatch(setShareInProgress(false))
  }

  /**
   * Disable Share.
   * @returns
   */
  async disableShare() {
    console.debug('disableShare()')

    if (!this._shareProducer) return

    this._shareProducer.close()

    this._dispatch(removeProducer(this._shareProducer.id))

    try {
      await this._protoo?.request('closeProducer', { producerId: this._shareProducer.id })
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${
          this._peerId
        }) Error closing server-side share Producer ${String(e)}`,
      )
      console.error(`Error closing server-side share Producer: ${e}`)
    }

    this._shareProducer = undefined
  }

  async startMicDevice() {
    try {
      this._localAudioTrack?.stop()
      this._dispatch(setMicStatus('ASKING_PERMISSIONS'))
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      this._localAudioTrack = stream.getAudioTracks()[0]
      this._micId = this._localAudioTrack.getSettings().deviceId
      await this._updateMics()
      this._dispatch(setMicStatus('STARTED'))
      this._dispatch(setMicActive(true))
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) startMicDevice failed ${String(e)}`,
      )
      console.error(e)
      this._dispatch(setMicStatus(this._getUserMediaErrorStatus(e)))
    }
  }

  async stopMicDevice() {
    console.log(`stopMicDevice`, this._localAudioTrack)
    this._localAudioTrack?.stop()
    this._localAudioTrack = undefined
    this._dispatch(setMicStatus('STOPPED'))
    this._dispatch(setMicActive(false))
  }

  /**
   *
   * @param deviceId
   */
  async changeMicDevice(deviceId: string) {
    this._localAudioTrack?.stop()

    try {
      this._dispatch(setMicStatus('ASKING_PERMISSIONS'))
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { ideal: deviceId } },
      })

      this._localAudioTrack = stream.getAudioTracks()[0]
      this._micId = deviceId
      this._dispatch(setMic(deviceId))
      this._dispatch(setMicStatus('STARTED'))

      if (this._micProducer) {
        await this._micProducer.replaceTrack({ track: this._localAudioTrack })
        this._dispatch(
          setProducerTrack({
            producerId: this._micProducer.id,
            track: this._localAudioTrack,
          }),
        )
      }
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) changeMicDevice failed ${String(e)}`,
      )
      console.error(e)
      this._dispatch(setMicStatus(this._getUserMediaErrorStatus(e)))
    }
  }

  /**
   *
   * @param deviceId
   */
  async changeSpeaker(deviceId: string) {
    this._speaker = this._speakers.get(deviceId)
    this._dispatch(setSpeaker(this._speaker?.deviceId))
  }

  async updateSpeakerDevices() {
    this._dispatch(setSpeakerInProgress(true))
    this._dispatch(setSpeakers([]))
    this._dispatch(setSpeaker())

    try {
      const speakersArray = await this._updateSpeakers()
      this._dispatch(
        setSpeakers(
          speakersArray.map((m) => ({ value: m.deviceId, label: m.label || m.deviceId })),
        ),
      )
      this._dispatch(setSpeaker(this._speaker?.deviceId))
    } catch (e) {
      console.error(e)
    }
    this._dispatch(setSpeakerInProgress(false))
  }

  /**
   * Restart ICE
   */
  async restartIce() {
    console.debug('restartIce()')

    this._dispatch(setRestartIceInProgress(true))

    try {
      if (this._sendTransport) {
        const iceParameters = await this._protoo?.request('restartIce', {
          transportId: this._sendTransport.id,
        })

        await this._sendTransport.restartIce({ iceParameters })
      }

      if (this._recvTransport) {
        const iceParameters = await this._protoo?.request('restartIce', {
          transportId: this._recvTransport.id,
        })

        await this._recvTransport.restartIce({ iceParameters })
      }

      console.info('ICE restarted')
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) restartIce failed ${String(e)}`,
      )
      console.error('restartIce() failed:%o', e)
    }

    this._dispatch(setRestartIceInProgress(false))
  }

  async setMaxSendingSpatialLayer(spatialLayer: number) {
    console.debug('setMaxSendingSpatialLayer() [spatialLayer:%s]', spatialLayer)

    try {
      if (!this._webcamProducer) {
        throw new Error('webcamProducer is null')
      }
      await this._webcamProducer.setMaxSpatialLayer(spatialLayer)
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${
          this._peerId
        }) setMaxSendingSpatialLayer failed ${String(e)}`,
      )
      console.error('setMaxSendingSpatialLayer() failed:%o', e)
    }
  }

  async setConsumerPreferredLayers(
    consumerId: string,
    spatialLayer: number,
    temporalLayer: number,
  ) {
    console.debug(
      'setConsumerPreferredLayers() [consumerId:%s, spatialLayer:%s, temporalLayer:%s]',
      consumerId,
      spatialLayer,
      temporalLayer,
    )

    try {
      await this._protoo?.request('setConsumerPreferredLayers', {
        consumerId,
        spatialLayer,
        temporalLayer,
      })

      this._dispatch(
        setConsumerPreferredLayersState({ consumerId, spatialLayer, temporalLayer }),
      )
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${
          this._peerId
        }) setConsumerPreferredLayers failed ${String(e)}`,
      )
      console.error('setConsumerPreferredLayers() failed:%o', e)
    }
  }

  async setConsumerPriority(consumerId: string, priority: number) {
    console.debug(
      'setConsumerPriority() [consumerId:%s, priority:%d]',
      consumerId,
      priority,
    )

    try {
      await this._protoo?.request('setConsumerPriority', { consumerId, priority })
      this._dispatch(setConsumerPriorityState({ consumerId, priority }))
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${
          this._peerId
        }) setConsumerPriority() failed ${String(e)}`,
      )
      console.error('setConsumerPriority() failed:%o', e)
    }
  }

  async requestConsumerKeyFrame(consumerId: string) {
    console.debug('requestConsumerKeyFrame() [consumerId:%s]', consumerId)

    try {
      await this._protoo?.request('requestConsumerKeyFrame', { consumerId })

      console.info('Keyframe requested for video consumer')
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${
          this._peerId
        }) requestConsumerKeyFrame failed ${String(e)}`,
      )
      console.error('requestConsumerKeyFrame() failed:%o', e)
    }
  }

  async enableChatDataProducer() {
    console.debug('enableChatDataProducer()')

    if (!this._useDataChannel) return

    // NOTE: Should enable this code but it's useful for testing.
    // if (this._chatDataProducer)
    //   return

    if (!this._sendTransport) {
      return
    }

    try {
      // Create chat DataProducer.
      this._chatDataProducer = await this._sendTransport.produceData({
        ordered: true,
        label: 'chat',
        appData: {},
      })

      this._dispatch(
        addDataProducer({
          id: this._chatDataProducer.id,
          sctpStreamParameters: this._chatDataProducer.sctpStreamParameters,
          label: this._chatDataProducer.label,
          protocol: this._chatDataProducer.protocol,
        }),
      )

      this._chatDataProducer.on('transportclose', () => {
        this._chatDataProducer = undefined
      })

      this._chatDataProducer.on('open', () => {
        console.debug('chat DataProducer "open" event')
      })

      this._chatDataProducer.on('close', () => {
        console.error('chat DataProducer "close" event')

        this._chatDataProducer = undefined
      })

      this._chatDataProducer.on('error', (error) => {
        console.error('chat DataProducer "error" event:%o', error)
      })

      this._chatDataProducer.on('bufferedamountlow', () => {
        console.debug('chat DataProducer "bufferedamountlow" event')
      })
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${
          this._peerId
        }) enableChatDataProducer failed ${String(e)}`,
      )
      console.error('enableChatDataProducer() failed:%o', e)
      throw e
    }
  }

  async sendChatMessage(text: string, chat: string) {
    console.debug('sendChatMessage() [text:"%s]', text)

    if (!this._chatDataProducer) {
      console.error('No chat DataProducer')
      return
    }

    try {
      this._chatDataProducer.send(JSON.stringify({ text, chat }))
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) DataProducer.send failed ${String(
          e,
        )}`,
      )
      console.error('chat DataProducer.send() failed:%o', e)
    }
  }

  async allowInvited(email: string) {
    this._protoo?.request('allowInvited', { peerId: email })
  }

  async prohibitInvited(email: string) {
    this._protoo?.request('prohibitInvited', { peerId: email })
  }

  async _joinRoom(webcamActive: boolean, micActive: boolean) {
    console.debug('_joinRoom()')

    try {
      this._mediasoupDevice = new mediasoupClient.Device({
        handlerName: this._handlerName,
      })

      const routerRtpCapabilities = await this._protoo?.request(
        'getRouterRtpCapabilities',
      )
      routerRtpCapabilities.headerExtensions =
        routerRtpCapabilities.headerExtensions.filter(
          (ext: any) => ext.uri !== 'urn:3gpp:video-orientation',
        )
      await this._mediasoupDevice.load({ routerRtpCapabilities })

      // NOTE: Stuff to play remote audios due to browsers' new autoplay policy.
      //
      // Just get access to the mic and DO NOT close the mic track for a while.
      // Super hack!
      /*
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        this._hackAudioTrack = stream.getAudioTracks()[0]
        this._hackAudioTrack.enabled = false
      })
      */

      // Create mediasoup Transport for sending (unless we don't want to produce).
      if (this._produce) {
        const transportInfo = await this._protoo?.request('createWebRtcTransport', {
          forceTcp: this._forceTcp,
          producing: true,
          consuming: false,
          sctpCapabilities: this._useDataChannel
            ? this._mediasoupDevice.sctpCapabilities
            : undefined,
        })

        const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } =
          transportInfo

        this._sendTransport = this._mediasoupDevice.createSendTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: ICE_SERVERS,
          proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
        })
        this._sendTransport.on(
          'connect',
          (
            { dtlsParameters },
            callback,
            errback, // eslint-disable-line no-shadow
          ) => {
            this._protoo
              ?.request('connectWebRtcTransport', {
                transportId: this._sendTransport?.id,
                dtlsParameters,
              })
              .then(callback)
              .catch((e: any) => {
                logger.error(
                  `RoomClient(${this._roomId} ${
                    this._peerId
                  }) sendTransport.connect failed ${String(e)}`,
                )
                errback(e)
              })
          },
        )

        this._sendTransport.on('connectionstatechange', (iceConnectionState) => {
          if (iceConnectionState === 'failed') {
            this.setNetworkTrouble(true)
            this._dispatch(setShowModalConnectionError(true))
            this._navigate(`/room/error-connection/${this._roomId}`)
          } else if (iceConnectionState === 'connected') {
            this.setNetworkTrouble(false)
            this._dispatch(setShowModalConnectionError(false))
          }
        })

        this._sendTransport.on(
          'produce',
          async ({ kind, rtpParameters, appData }, callback, errback) => {
            try {
              // eslint-disable-next-line no-shadow
              const { id } = await this._protoo?.request('produce', {
                transportId: this._sendTransport?.id,
                kind,
                rtpParameters,
                appData,
              })

              callback({ id })
            } catch (e: any) {
              logger.error(
                `RoomClient(${this._roomId} ${
                  this._peerId
                }) sendTransport.on(produce) failed ${String(e)}`,
              )
              errback(e)
            }
          },
        )

        this._sendTransport.on(
          'producedata',
          async (
            { sctpStreamParameters, label, protocol, appData },
            callback,
            errback,
          ) => {
            console.debug(
              '"producedata" event: [sctpStreamParameters:%o, appData:%o]',
              sctpStreamParameters,
              appData,
            )

            try {
              // eslint-disable-next-line no-shadow
              const { id } = await this._protoo?.request('produceData', {
                transportId: this._sendTransport?.id,
                sctpStreamParameters,
                label,
                protocol,
                appData,
              })

              callback({ id })
            } catch (e: any) {
              logger.error(
                `RoomClient(${this._roomId} ${this._peerId}) produceData failed ${String(
                  e,
                )}`,
              )
              errback(e)
            }
          },
        )
      }

      // Create mediasoup Transport for receiving (unless we don't want to consume).
      if (this._consume) {
        const transportInfo = await this._protoo?.request('createWebRtcTransport', {
          forceTcp: this._forceTcp,
          producing: false,
          consuming: true,
          sctpCapabilities: this._useDataChannel
            ? this._mediasoupDevice.sctpCapabilities
            : undefined,
        })

        const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } =
          transportInfo

        this._recvTransport = this._mediasoupDevice.createRecvTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: ICE_SERVERS,
        })

        this._recvTransport.on(
          'connect',
          (
            { dtlsParameters },
            callback,
            errback, // eslint-disable-line no-shadow
          ) => {
            this._protoo
              ?.request('connectWebRtcTransport', {
                transportId: this._recvTransport?.id,
                dtlsParameters,
              })
              .then(callback)
              .catch((e: any) => {
                logger.error(
                  `RoomClient(${this._roomId} ${
                    this._peerId
                  }) recvTransport.on(connect) failed ${String(e)}`,
                )
                errback(e)
              })
          },
        )
      }

      // Join now into the room.
      // NOTE: Don't send our RTP capabilities if we don't want to consume.
      const { peers } = await this._protoo?.request('join', {
        peerId: this._peerId,
        rtpCapabilities: this._consume
          ? this._mediasoupDevice.rtpCapabilities
          : undefined,
        sctpCapabilities:
          this._useDataChannel && this._consume
            ? this._mediasoupDevice.sctpCapabilities
            : undefined,
      })

      this._dispatch(setRoomState('joined'))

      for (const peer of peers) {
        this._dispatch(addPeer({ ...peer, consumers: [], dataConsumers: [] }))
      }

      // Enable mic/webcam.
      if (this._produce) {
        // Set our media capabilities.
        this._dispatch(
          setMediaCapabilities({
            canSendMic: this._mediasoupDevice.canProduce('audio'),
            canSendWebcam: this._mediasoupDevice.canProduce('video'),
          }),
        )

        if (micActive) {
          this.enableMic()
        }

        if (webcamActive) {
          this.enableWebcam(this._bokehActive)
        }

        this._sendTransport?.on('connectionstatechange', (connectionState) => {
          console.log('_sendTransport.connectionstatechange', connectionState)
          if (connectionState === 'connected') {
            this.enableChatDataProducer()
          }
        })
      }
    } catch (e: any) {
      if (e instanceof Error) {
        switch (e.message) {
          case 'PEER_NOT_YET_ALLOWED':
            this.emit('PEER_NOT_YET_ALLOWED')
            break
        }
      }

      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) joinRoom failed ${String(e)}`,
      )
      console.error('_joinRoom() failed:%o', e)
    }
  }

  async _updateWebcams() {
    console.debug('_updateWebcams()')

    // Reset the list.
    this._webcams = new Map()
    const webcamsArray = []
    const devices = await navigator.mediaDevices.enumerateDevices()

    for (const device of devices) {
      if (device.kind !== 'videoinput') {
        continue
      }

      this._webcams.set(device.deviceId, device)
      webcamsArray.push({
        value: device.deviceId,
        label: device.label || device.deviceId,
      })
    }

    console.debug('_updateWebcams() [webcams:%o]', webcamsArray)

    this._dispatch(setCanChangeWebcam(this._webcams.size > 1))
    this._dispatch(setWebcams(webcamsArray))
    this._dispatch(setWebcam(this._webcam.id))
  }

  async _updateMics() {
    console.debug('_updateMics()')

    // Reset the list.
    this._mics = new Map()
    const micsArray = []
    await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    const devices = await navigator.mediaDevices.enumerateDevices()

    for (const device of devices) {
      if (device.kind !== 'audioinput') {
        continue
      }

      this._mics.set(device.deviceId, device)
      micsArray.push({ value: device.deviceId, label: device.label || device.deviceId })
    }

    this._dispatch(setMics(micsArray))
    this._dispatch(setMic(this._micId))
  }

  async _updateSpeakers(): Promise<MediaDeviceInfo[]> {
    // Reset the list.
    this._speakers = new Map()
    const speakersArray = []
    const devices = await navigator.mediaDevices.enumerateDevices()

    for (const device of devices) {
      if (device.kind !== 'audiooutput') {
        continue
      }

      this._speakers.set(device.deviceId, device)
      speakersArray.push(device)
    }

    if (speakersArray.length === 0) {
      this._speaker = undefined
    } else if (!this._speakers.has(this._speaker?.deviceId || '')) {
      this._speaker = speakersArray[0]
    }

    return speakersArray
  }

  _getWebcamType(deviceId: string) {
    const label = this._webcams.get(deviceId)?.label || 'rear'
    return /(back|rear)/i.test(label) ? 'back' : 'front'
  }

  async _pauseConsumer(consumer: mediasoupClient.types.Consumer) {
    if (consumer.paused) return

    try {
      await this._protoo?.request('pauseConsumer', { consumerId: consumer.id })
      consumer.pause()
      this._dispatch(setConsumerPaused({ consumerId: consumer.id, originator: 'local' }))
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) pauseConsumer failed ${String(e)}`,
      )
    }
  }

  async _resumeConsumer(consumer: mediasoupClient.types.Consumer) {
    if (!consumer.paused) return

    try {
      await this._protoo?.request('resumeConsumer', { consumerId: consumer.id })

      consumer.resume()

      this._dispatch(setConsumerResumed({ consumerId: consumer.id, originator: 'local' }))
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) resumeConsumer failed ${String(e)}`,
      )
      console.error('_resumeConsumer() | failed:%o', e)
    }
  }

  async setOutputCanvasRef(
    canvas?: RefObject<HTMLCanvasElement>,
    videoRef?: RefObject<HTMLVideoElement>,
  ) {
    this._outputCanvasRef = canvas
    this._inputVideoRef = videoRef

    if (this._bokehActive) {
      if (this._webcamProducer) {
        if (this._localVideoTrack) {
          this._dispatch(
            setProducerTrack({
              producerId: this._webcamProducer.id,
              track: this._localVideoTrack,
            }),
          )
        }

        const localBokehVideoTrack = this.getLocalBokehVideoTrack()
        if (localBokehVideoTrack) {
          await this._webcamProducer.replaceTrack({ track: localBokehVideoTrack })
        }
      }
    }
  }

  async toggleBokehEffect(active: boolean) {
    this._bokehActive = active
    this._dispatch(setBokehEffectActive(active))

    if (active) {
      this._dispatch(setBokehEffectInProgress(true))
      // await this.changeWebcamResolution('vga')
      if (this._outputCanvasRef && this._inputVideoRef) {
        await this._renderPrediction()
      }
      this._dispatch(setBokehEffectInProgress(false))
    } else {
      // this.changeWebcamResolution('hd')
    }

    if (this._webcamProducer) {
      if (this._localVideoTrack) {
        this._dispatch(
          setProducerTrack({
            producerId: this._webcamProducer.id,
            track: this._localVideoTrack,
          }),
        )
      }

      if (active) {
        const localBokehVideoTrack = this.getLocalBokehVideoTrack()

        if (localBokehVideoTrack) {
          await this._webcamProducer.replaceTrack({ track: localBokehVideoTrack })
        }
      } else {
        if (this._localVideoTrack) {
          await this._webcamProducer.replaceTrack({ track: this._localVideoTrack })
        }
      }
    }
  }

  async _renderPrediction() {
    try {
      await this._renderResult()

      if (this._bokehActive && !this._closed) {
        setTimeout(() => this._renderPrediction(), BOKEH_VIDEO_FREQUENCY)
      }
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) renderPrediction failed ${String(
          e,
        )}`,
      )
      console.error(e)
    }
  }

  async _renderResult() {
    if (!this._inputVideoRef?.current || !this._outputCanvasRef?.current) {
      return
    }

    try {
      if (this._inputVideoRef.current.readyState < 2) {
        return
      }

      const segmenter = await RoomClient._segmenter
      const segmentation = await segmenter.segmentPeople(this._inputVideoRef.current, {
        flipHorizontal: false,
        multiSegmentation: false,
        segmentBodyParts: true,
        segmentationThreshold: 0.5,
      })

      if (!segmentation) {
        return
      }

      const videoWidth = this._inputVideoRef.current.videoWidth
      const videoHeight = this._inputVideoRef.current.videoHeight
      this._outputCanvasRef.current.width = videoWidth
      this._outputCanvasRef.current.height = videoHeight

      await bodySegmentation.drawBokehEffect(
        this._outputCanvasRef.current,
        this._inputVideoRef.current,
        segmentation,
        0.5, // options.foregroundThreshold
        15, // options.backgroundBlur,
        8, // options.edgeBlur
      )
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) renderResult failed ${String(e)}`,
      )
      console.error(e)
    }
  }

  async setNetworkTrouble(isTrouble: boolean) {
    try {
      await this._protoo?.request('setNetworkTrouble', { isTrouble })
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${this._peerId}) setNetworkTrouble failed ${String(
          e,
        )}`,
      )
      console.error('setNetworkTrouble() failed: %o', e)
    }
  }

  // async _resetBackend() {
  //   const ENGINE = tf.engine()
  // }

  getLocalBokehVideoTrack() {
    if (!this._outputCanvasRef?.current) {
      return
    }

    try {
      // The trick/hack is to draw something in canvas before calling
      // captureStream(). Otherwise it will throw an error
      const canvas = this._outputCanvasRef?.current as any
      const ctx = canvas.getContext('2d')
      ctx.beginPath()
      ctx.rect(0, 0, canvas.width, canvas.height)
      ctx.fillStyle = '#224060'
      ctx.fill()

      const bokehStream: MediaStream = canvas.captureStream()
      return (this._localBokehVideoTrack = bokehStream.getTracks()[0])
    } catch (e) {
      logger.error(
        `RoomClient(${this._roomId} ${
          this._peerId
        }) getLocalBokehVideoTrack failed ${String(e)}`,
      )
      console.error(e)
    }
  }
}

RoomClient._segmenter = bodySegmentation.createSegmenter(
  bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation,
  {
    runtime: 'mediapipe',
    modelType: 'general',
    solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@${mpSelfieSegmentation.VERSION}`,
  },
)
