import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { AppDispatch, RootState } from '../../store'
import { connectInvited, idleConnectStatus } from './redux/inviteSlice'
import { Alert } from 'antd'
import { BiError } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { LanguageSwitch } from '../../components/LanguageSwitch'
import './invite.scss'
import { authLogoFilename, callLogoFilename } from '../../assets/personnalization'
import { Link } from 'react-router-dom'
import { FiArrowLeftCircle } from 'react-icons/fi'

function Invite() {
  const { token, action } = useParams()
  const { t } = useTranslation('invite')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const roomId = useSelector((state: RootState) => state.invite.roomId)
  const connectStatus = useSelector((state: RootState) => state.invite.connectStatus)
  const connectError = useSelector((state: RootState) => state.invite.connectError)

  useEffect(() => {
    if (token) {
      dispatch(connectInvited({ token }))
    }

    return () => {
      dispatch(idleConnectStatus())
    }
  }, [])

  useEffect(() => {
    switch (connectStatus) {
      case 'success':
        switch (action) {
          case 'vote':
            navigate(`/vote/${roomId}`)
            break
          default:
            navigate(`/room/${roomId}`)
        }
    }
  }, [connectStatus])

  return (
    <>
      {connectStatus !== 'TOKEN_NOT_FOUND' && (
        <div className="Invite">
          <div className="invite-content">
            <div className="invite-title-container mb-1rem">
              <div className="logo-container">
                <img
                  className="invite-logo"
                  src={`/logo/${authLogoFilename}`}
                  alt="Auxasuite"
                />
              </div>
              <div className="language-switch">
                <LanguageSwitch />
              </div>
            </div>
            {connectStatus === 'loading' && (
              <div className="feedback-container">
                {t('Thank you! You will be connected the meeting immediately.')}
              </div>
            )}
            {connectStatus === 'TOKEN_ALREADY_USED' && (
              <div>
                <Alert
                  type="warning"
                  showIcon
                  description={t(
                    'Your single-use access link has already been used. For security reasons, please request a new connection link below.',
                  )}
                />
                <div className="mb-1rem">
                  {t(
                    'You can request another access link that will be sent to your email.',
                  )}
                </div>
              </div>
            )}
            {connectStatus === 'UNKNOWN_ERROR' && (
              <div className="feedback-container">
                <BiError size="4em" color="#ff4d4f" />
                <div>{t('An error occurred.')}</div>
              </div>
            )}
          </div>
        </div>
      )}

      {connectStatus === 'TOKEN_NOT_FOUND' && (
        <div className="Antechamber">
          <div className="Antechamber-header-container">
            <div className="Antechamber-header">
              <img
                src={`/logo/${callLogoFilename}`}
                className="Antechamber-header-medscope"
                alt="Logo"
              />

              <div className="d-flex d-flex-middle">
                <Link
                  onClick={() => navigate('/login')}
                  to="/login"
                  className="Antechamber-back"
                >
                  <FiArrowLeftCircle size="2rem" strokeWidth={1} />
                  <span className="ml-05rem hideOnMobile">
                    {t('Go to the login page')}
                  </span>
                </Link>
                <div className="ml-1rem">
                  <LanguageSwitch light={true} />
                </div>
              </div>
            </div>
          </div>

          <div className="Antechamber-main">
            <div className="Antechamber-connection-error">
              <BiError size="4em" color="#ff4d4f" className="mt-2rem" />
              <div className="Antechamber-connection-error">
                {t('You are not authorized to access this meeting.')}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Invite
