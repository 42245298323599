import React from 'react'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Spin, Tooltip } from 'antd'
import { ServiceUnavailableError } from '../ServiceUnavailableError'
import { MdOutlineFolderOff } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { FolderInterface, setCreateDrive } from '../../features/Drives/redux/drivesSlice'
import WelcomeDrives from './WelcomeDrives/WelcomeDrives'
import DrivesHeader from './DrivesHeader/DrivesHeader'
import DriveListContent from './DriveListContent/DriveListContent'

interface Props {
  adminRootFolders: FolderInterface[]
  sharedRootFolders: FolderInterface[]
}

function DrivesContent({ adminRootFolders, sharedRootFolders }: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const fetchDrivesStatus = useSelector(
    (state: RootState) => state.drive.fetchDrivesStatus,
  )
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const drives = useSelector((state: RootState) => state.drive.drives)
  const access = useSelector((state: RootState) => state.auth.access)

  if (fetchDrivesStatus === 'loading') {
    return (
      <div className="d-flex d-flex-center d-flex-middle 2rem">
        <Spin size="large" />
      </div>
    )
  }

  if (
    fetchDrivesStatus === 'SERVICE_UNAVAILABLE' ||
    fetchDrivesStatus === 'unknown_error'
  ) {
    return <ServiceUnavailableError />
  }

  if (!drives || drives.length === 0) {
    return (
      <div className="Drives-empty-container">
        <MdOutlineFolderOff size="2.5em" />
        <h3>{t('You currently have no drives. Please click here to create one:')}</h3>
        <Tooltip
          title={
            !access?.organization
              ? t('You do not have the necessary rights.', { ns: 'common' })
              : ''
          }
        >
          <Button
            type="primary"
            onClick={() => dispatch(setCreateDrive(true))}
            disabled={!access?.organization}
          >
            {t('Create drive')}
          </Button>
        </Tooltip>
      </div>
    )
  }

  return (
    <div className="Drives-main-container">
      {(location.pathname.includes('/drives/home') || !selectedDriveFolder) && (
        <WelcomeDrives
          adminRootFolders={adminRootFolders}
          sharedRootFolders={sharedRootFolders}
        />
      )}

      {location.pathname.includes('/drives/my-drives') && (
        <>
          <DrivesHeader />
          <DriveListContent />
        </>
      )}
    </div>
  )
}

export default DrivesContent
