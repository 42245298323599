import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import MeetingVote from './MeetingVote'
import { fetchRoomById } from '../../../utils/MeetingUtils'
import { useParams } from 'react-router-dom'
import { Col, Row, Spin } from 'antd'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import {
  headerLogoFilename,
  headerLogoMobileFilename,
} from '../../../assets/personnalization'
import { LanguageSwitch } from '../../../components/LanguageSwitch'
import { useFetchData } from 'auxasphere-react-kit'
import { BiError } from 'react-icons/bi'
import './MeetingVote.scss'
import '../../../components/Layout/Layout.scss'

function InviteMeetingVote() {
  const { roomId } = useParams()
  dayjs.extend(LocalizedFormat)
  const { t, i18n } = useTranslation('meetings')
  const auth = useSelector((state: RootState) => state.invite)
  const [voted, setVoted] = useState(false)
  const currentYear = new Date().getFullYear()

  const { fetchedData: room, isLoading } = useFetchData(
    () => fetchRoomById(roomId || '', auth.jwt || ''),
    () => null,
  )

  return (
    <div className="layout">
      <header>
        <div className="header-left header-left--light">
          <div className="logo">
            <img
              className="logo-image"
              src={`/logo/${headerLogoFilename}`}
              alt="Auxasuite logo large"
            />
            <img
              className="logo-image-small"
              src={`/logo/${headerLogoMobileFilename}`}
              alt="Auxasuite logo"
            />
          </div>
        </div>
        <div className="header-right">
          <LanguageSwitch />
        </div>
      </header>
      <div className="vote-content">
        {isLoading ? (
          <div className="d-flex d-flex-center d-flex-middle 2rem">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {voted ? (
              <Row justify="center" className="mt-1rem">
                <Col span={24}>
                  <h1 className="text-center">
                    {t('Thank you, your preferences have been taken into account.')}
                  </h1>
                </Col>
              </Row>
            ) : room ? (
              <Row justify="center" className="mt-1rem">
                <Col span={20} sm={18}>
                  <MeetingVote room={room} close={() => setVoted(true)} invited={true} />
                </Col>
              </Row>
            ) : (
              <>
                <div className="not-authorized-message">
                  <BiError size="4em" color="#ff4d4f" />
                  <div>{t('You are not authorized to access this page.')}</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <footer>
        <div>
          Auxasuite {currentYear} -&nbsp;
          <a
            className="footer-document"
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_STATIC_ASSETS_BASE_URL}/legal-notice_${i18n.language}.pdf`}
          >
            {t('Legal notice', { ns: 'common' })}
          </a>
        </div>
      </footer>
    </div>
  )
}

export default InviteMeetingVote
