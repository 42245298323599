import { useEffect, useState, type Dispatch, type SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import {
  fetchUsers,
  idleUsers,
} from '../../features/Organisation/redux/organisationSlice'

//TODO: [Nils] add strong typing to sortField & sortOrder (i.e. remove string in their types)
interface Sorter {
  sortField: 'last_name' | string
  sortOrder: 'ASC' | string
}
interface PageDetails {
  page: number
  usersPerPage: number
}
export default function useParticipantsTableSorter({ page, usersPerPage }: PageDetails) {
  const dispatch = useDispatch<AppDispatch>()

  const [sorter, setSorter] = useState<Sorter>({
    sortField: 'last_name',
    sortOrder: 'ASC',
  })

  const [pageDetails, setPageDetails] = useState<PageDetails>({ page, usersPerPage })

  const updateTable = () => {
    dispatch(
      fetchUsers({
        page: pageDetails.page,
        usersPerPage: pageDetails.usersPerPage,
        sortField: sorter.sortField,
        sortOrder: sorter.sortOrder,
      }),
    )
  }

  useEffect(() => {
    updateTable()
    return () => {
      dispatch(idleUsers()) //TODO: refactor
    }
  }, [pageDetails])

  return {
    sorter,
    setSorter,
    pageDetails,
    setPageDetails,
    updateTable,
  }
}
