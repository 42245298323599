import { useEffect, useState } from 'react'

/**
 * Custom hook that checks if the content inside a referenced element is truncated.
 * It monitors both the element's dimensions (by adding a resize event listener)
 * and the provided text to ensure the tooltip updates when the text or window size changes.
 *
 * @param ref - Reference to the HTML element containing the text
 * @param text - The text to check if it overflows the container
 * @returns {boolean} - Returns true if the content is truncated, false otherwise
 */
export const UseTruncateText = (ref: React.RefObject<HTMLElement>, text?: string) => {
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    const checkTruncation = () => {
      if (ref.current) {
        setIsTruncated(ref.current.scrollWidth > ref.current.clientWidth)
      }
    }

    checkTruncation()
    window.addEventListener('resize', checkTruncation)

    return () => {
      window.removeEventListener('resize', checkTruncation)
    }
  }, [ref, text])

  return isTruncated
}
