import retry from 'retry'
import { AppDispatch } from '../../store'
import {
  addLiveNotification,
  attemptFetchLiveNotifications,
  Notification,
} from './liveWidgetSlice'
import {
  Message,
  WebsocketConnection,
} from '../ChatComponent/WebsocketConnection/WebsocketConnection'

export default class Notifier extends WebsocketConnection {
  constructor(dispatch: AppDispatch, jwt: string, me: string) {
    super(dispatch, jwt, me)
  }

  public async connect() {
    const url = `${process.env.REACT_APP_NOTIFICATION_BASE_WS_URL}?token=${this._jwt}`
    super.connect(url)
  }

  // Function to handle incoming events
  public handleMessage(data: Message): void {
    const notificaion = data as Notification
    switch (notificaion.type) {
      case 'CHAT_MESSAGE':
      case 'DRIVE_CREATED':
      case 'DRIVE_DELETED':
      case 'PARTICIPANT_ADDED_TO_DRIVE':
      case 'PARTICIPANT_REMOVED_FROM_DRIVE':
        this._dispatch(addLiveNotification(notificaion))
        break
      case 'DELETED':
        this._dispatch(attemptFetchLiveNotifications())
        break
    }
  }

  public updateDisconnectTimeout() {
    clearTimeout(this.disconnectTimeout)

    this.disconnectTimeout = setTimeout(() => {
      this.connect()
    }, 10000)
  }

  public updateConnectionState(connected: boolean) {
    // this._dispatch(setConnected(connected))
  }
}
