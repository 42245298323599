import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { setHideOnMouseStop } from '../../features/room/redux/roomSlice'

/**
 *
 */
export function UseCallHideItemsOnMouseStop() {
  const dispatch = useDispatch<AppDispatch>()

  useIdleTimer({
    onIdle: () => {
      dispatch(setHideOnMouseStop(true))
    },
    onAction: () => {
      dispatch(setHideOnMouseStop(false))
    },
    timeout: 3_000,
    events: ['mousemove'],
  })
}
