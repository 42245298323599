import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { useParams, useNavigate } from 'react-router-dom'
import './RoomConnectionError.scss'
import ConnectionError from '../../components/ConnectionError/ConnectionError'
import { Trans, useTranslation } from 'react-i18next'
import { ENV_CONSTANTS } from '../../utils/Utils'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { setShowModalConnectionError } from './redux/meSlice'

function RoomConnectionError() {
  const { roomId } = useParams()
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(8)
  const { t } = useTranslation('room')
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1)
    }, 1000)

    const timeout = setTimeout(() => {
      navigate(`/room/${roomId}`)
    }, 8000)

    dispatch(setShowModalConnectionError(false))

    return () => {
      clearInterval(interval)
      clearTimeout(timeout)
    }
  }, [roomId, navigate])

  return (
    <Layout
      content={
        <div className="RoomConnectionError">
          <h2 className="title">
            <Trans
              i18nKey="UNABLE_CONNECT_CALL"
              shouldUnescape={true}
              ns="room"
              values={{ applicationName: ENV_CONSTANTS['DEFAULT_APPLICATION_NAME'] }}
            />
          </h2>
          <div className="reconnection-container">
            <ConnectionError />
            <p className="explanation">
              {t(
                'Please ensure that your device has an active Internet connection and that all device and network security solutions have been configured to allow access to our servers.',
              )}
              <br />
              <br />
              {t('For further information, please see the ', { ns: 'faq' })}
              <a href="/faq/network" target="_blank">
                {t('network configuration page', { ns: 'faq' })}
              </a>
            </p>
            <h3 className="reconnection-message">
              {t('Reconnection in progress...')} {countdown}
            </h3>
          </div>
        </div>
      }
      contentFullSize={true}
      primaryContent={true}
      footer={true}
    />
  )
}

export default RoomConnectionError
