import React from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { buildCalendarUrl } from '../../features/calendar/CalendarService'
import dayjs from 'dayjs'
import './HeaderMenuNavigation.scss'
import classNames from 'classnames'
import { LOGO } from '../../assets/logos'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

function HeaderMenuApplications() {
  const { t } = useTranslation('layout')
  const access = useSelector((state: RootState) => state.auth.access)

  return (
    <div className="menu-navigation-container">
      <h3 className="main-title">{t('Applications')}</h3>
      <div className="menu-navigation-content">
        {access?.meetings && (
          <a
            className={classNames({
              'menu-navigation-element': true,
              'menu-navigation-element--active-meeting':
                location.pathname.includes('meeting'),
            })}
            href="/upcoming-meetings"
          >
            <span className="menu-navigation-logo-container">
              <img src={LOGO.logo_auxameetings} className="menu-navigation-logo" />
            </span>
            Meetings
          </a>
        )}

        {access?.planning && (
          <a
            className={classNames({
              'menu-navigation-element': true,
              'menu-navigation-element--active-calendar':
                location.pathname.includes('calendar'),
            })}
            href={buildCalendarUrl(dayjs().toString(), 'week')}
          >
            <span className="menu-navigation-logo-container">
              <img src={LOGO.logo_auxaplanning} className="menu-navigation-logo" />
            </span>
            Planning
          </a>
        )}

        {access?.team && (
          <a
            className={classNames({
              'menu-navigation-element': true,
              'menu-navigation-element--active-messages':
                location.pathname.includes('messages'),
            })}
            href="/messages"
          >
            <span className="menu-navigation-logo-container">
              <img src={LOGO.logo_auxateam} className="menu-navigation-logo" />
            </span>
            Team
          </a>
        )}

        {access?.drive && (
          <a
            className={classNames({
              'menu-navigation-element': true,
              'menu-navigation-element--active-drives':
                location.pathname.includes('drives'),
            })}
            href="/drives/home"
          >
            <span className="menu-navigation-logo-container">
              <img src={LOGO.logo_auxadrive} className="menu-navigation-logo" />
            </span>
            Drive
          </a>
        )}

        <Tooltip title={t('Coming soon', { ns: 'common' })}>
          <div className="menu-navigation-element">
            <span className="menu-navigation-logo-container">
              <img src={LOGO.logo_auxatransfer} className="menu-navigation-logo" />
            </span>
            Transfer
          </div>
        </Tooltip>
        {/* <a
          className="menu-navigation-element"
          href="https://medecin-demo.temma.care"
          rel="noreferrer"
          target="_blank"
        >
          <span className="menu-navigation-logo-container">
            <img src={LOGO.logo_auxadoc} className="menu-navigation-logo" />
          </span>
          Auxadoc
        </a> */}
      </div>
    </div>
  )
}

export default HeaderMenuApplications
