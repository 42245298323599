import React, { useEffect, useRef } from 'react'

interface Props {
  onType: () => void
  onStopTyping: () => void
  text: string
}

function useChangeTypingChecker({ onType, onStopTyping, text }: Props) {
  const lastTypedAtRef = useRef<number>()

  useEffect(() => {
    const interval = setInterval(() => {
      if (!lastTypedAtRef.current) {
        return
      }

      if (Date.now() - lastTypedAtRef.current > 4000) {
        lastTypedAtRef.current = undefined
        onStopTyping()
      } else {
        // Fire onType each second if last type < 4s
        onType()
      }
    }, 1000)

    return () => {
      clearInterval(interval)
      onStopTyping()
    }
  }, [])

  useEffect(() => {
    const isTextNotEmpty = text.replace(/(<([^>]+)>)/gi, '').length > 0
    if (isTextNotEmpty) {
      // Fire immediately on first letter
      if (!lastTypedAtRef.current) {
        onType()
      }

      lastTypedAtRef.current = Date.now()
    } else {
      if (lastTypedAtRef.current) {
        lastTypedAtRef.current = undefined
        onStopTyping()
      }
    }
  }, [text])
}

export default useChangeTypingChecker
