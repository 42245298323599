import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../store'
import { WebSocketConnectionContext } from '../WebsocketConnection/WebSocketConnectionContext'
import { Chat, setChatToModify, updateChatToModify } from '../Chat/chatsSlice'
import { Button, Form, Input, Modal } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import ModifyChatPeers from './ModifyChatPeers'
import {
  SearchGroupDTO,
  SearchUserDTO,
} from '../../../features/Organisation/OrganisationService'

interface Props {
  chat: Chat
  currentSquadId: string
}

function ModifyChatDialog({ chat, currentSquadId }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('chat')
  const ws = useContext(WebSocketConnectionContext)
  const [form] = Form.useForm()
  const [original, setOriginal] = useState<{
    title: string
    users: string
    groups: string
  }>()
  const [archiveConfirmation, setArchiveConfirmation] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      title: chat.title,
    })

    setOriginal({
      title: chat.title,
      users: JSON.stringify(chat.peers),
      groups: JSON.stringify(chat.groups),
    })
  }, [])

  function onSubmit({ title }: { title: string }) {
    if (title != original?.title) {
      ws?.sendUpdateChatTitle(chat.id, title)
    }

    if (
      JSON.stringify(chat.peers) != original?.users ||
      JSON.stringify(chat.groups) != original?.groups
    ) {
      ws?.sendUpdateChatPeers(
        chat.id,
        chat.peers.map((p) => p.email),
        chat.groups.map((g) => g.id),
      )
    }

    dispatch(setChatToModify(undefined))

    form.setFieldsValue({
      title: '',
    })
  }

  function updatePeers(users: SearchUserDTO[]) {
    dispatch(updateChatToModify({ title: chat.title, users: users, groups: chat.groups }))
  }

  function updateGroups(groups: SearchGroupDTO[]) {
    dispatch(updateChatToModify({ title: chat.title, users: chat.peers, groups }))
  }

  function archive() {
    ws?.sendArchiveChat(chat.id)
    setArchiveConfirmation(false)
    dispatch(setChatToModify(undefined))
  }

  return archiveConfirmation ? (
    <Modal
      title={t('Discussion channel management')}
      open={true}
      onCancel={() => setArchiveConfirmation(false)}
      onOk={archive}
      cancelText={t('No', { ns: 'common' })}
      okText={t('Yes', { ns: 'common' })}
      className="confirm-archive-group"
      centered
    >
      <p>
        <Trans
          i18nKey="ARCHIVE_DISCUSSION"
          ns="chat"
          values={{ name: chat.title }}
          components={{ strong: <strong /> }}
          shouldUnescape={true}
        />
      </p>
    </Modal>
  ) : (
    <Modal
      title={t('Discussion channel management')}
      open={true}
      centered
      onCancel={() => dispatch(setChatToModify(undefined))}
      width={'70em'}
      footer={[
        <>
          <Button
            danger
            type="primary"
            onClick={() => setArchiveConfirmation(true)}
            style={{ marginRight: 'auto' }}
          >
            {t('Delete the channel')}
          </Button>
          <Button key="cancel" onClick={() => dispatch(setChatToModify(undefined))}>
            {t('footer_Cancel', { ns: 'modal' })}
          </Button>
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            {t('Modify', { ns: 'groups' })}
          </Button>
        </>,
      ]}
    >
      <div className="chat-modal">
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            name="title"
            label={t('Discussion channel name')}
            className="chat_create_title_input"
            rules={[
              { required: true, message: t('Please input a discussion channel name') },
            ]}
          >
            <Input placeholder={t('Discussion channel name')} />
          </Form.Item>

          <label>{t('Participants')}</label>
        </Form>

        <ModifyChatPeers
          peers={chat.peers || []}
          groups={chat.groups || []}
          onUsersUpdate={updatePeers}
          onGroupsUpdate={updateGroups}
        />
      </div>
    </Modal>
  )
}

export default ModifyChatDialog
