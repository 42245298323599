import { createAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../../store'
import { setConnected } from './appWebsocketSlice'
import { Chat, ChatMessage } from '../Chat/chatsSlice'
import { Squad } from '../Squad/squadListSlice'
import { ChatActions, Message, WebsocketConnection } from './WebsocketConnection'
import { OrganizationRole } from '../../../features/Organisation/OrganizationInterface'

export enum ChatMessageType {
  SQUAD = 'SQUAD',
  SQUAD_NOT_EXIST = 'SQUAD_NOT_EXIST',
  CHAT_LIST = 'CHAT_LIST',
  CHAT_DETAILS = 'CHAT_DETAILS',
  CHAT_DETAILS_LIST = 'CHAT_DETAILS_LIST',
  CHAT_CREATED = 'CHAT_CREATED',
  CHAT_UPDATED = 'CHAT_UPDATED',
  CHAT_ARCHIVED = 'CHAT_ARCHIVED',
  CHAT_UNARCHIVED = 'CHAT_UNARCHIVED',
  CHAT_MESSAGES = 'CHAT_MESSAGES',
  CHAT_MESSAGE_RECEIVED = 'CHAT_MESSAGE_RECEIVED',
  CHAT_MESSAGES_SEEN = 'CHAT_MESSAGES_SEEN',
  CHAT_USER_ADDED = 'CHAT_USER_ADDED',
  CHAT_USER_REMOVED = 'CHAT_USER_REMOVED',
  CHAT_USERS_UPDATED = 'CHAT_USERS_UPDATED',
  PEER_STATUS = 'PEER_STATUS',
  HEARTBEAT = 'HEARTBEAT',
  CHAT_MESSAGE_DELETED = 'CHAT_MESSAGE_DELETED',
  CHAT_MESSAGE_EDITED = 'CHAT_MESSAGE_EDITED',
  CHAT_USER_TYPING = 'CHAT_USER_TYPING',
  OPEN_CHAT_REQUEST = 'OPEN_CHAT_REQUEST',
  WEBRTC_SIGNALING = 'WEBRTC_SIGNALING',
  CHAT_MESSAGE_REACTION_ADDED = 'CHAT_MESSAGE_REACTION_ADDED',
  CHAT_MESSAGE_REACTION_DELETED = 'CHAT_MESSAGE_REACTION_DELETED',
}

interface SquadMessage extends Message {
  squad: Squad
  peersOnline: string[]
}

interface SquadNotExistMessage extends Message {
  squadId: string
}

interface ChatListMessage extends Message {
  squadId: string
  chatList: Chat[]
}

interface ChatDetailsMessage extends Message {
  squadId: string
  chatId: string
  lastMessage: ChatMessage
  unreadMessagesCount: number
  chatActivity: {
    peerEmail: string
    peerFirstName: string
    peerLastName: string
    seenMessagesAt: string
  }[]
}

interface ChatDetailsListMessage extends Message {
  squadId: string
  details: {
    chatId: string
    lastMessage: ChatMessage
    unreadMessagesCount: number
    chatActivity: {
      peerEmail: string
      peerFirstName: string
      peerLastName: string
      seenMessagesAt: string
    }[]
  }[]
}

interface ChatMessagesSeenMessage extends Message {
  chatId: string
  peerEmail: string
  peerFirstName: string
  peerLastName: string
  seenMessagesAt: string
}

interface ChatCreatedMessage extends Message {
  chat: Chat
}

interface ChatUpdatedMessage extends Message {
  chatId: string
  title: string
}

interface ChatArchivedMessage extends Message {
  chatId: string
}

interface ChatUnArchivedMessage extends Message {
  chat: Chat
}

interface ChatMessagesMessage extends Message {
  squadId: string
  chatId: string
  messages: ChatMessage[]
  chunk: number
  newestChunk: number
}

interface ChatMessagesMessage extends Message {
  squadId: string
  chatId: string
  messages: ChatMessage[]
  chunk: number
  newestChunk: number
}

interface ChatMessageReceivedMessage extends Message {
  squadId: string
  chatId: string
  message: ChatMessage
}

interface ChatMessageDeletedMessage extends Message {
  squadId: string
  chatId: string
  messageId: string
  deleted: boolean
}

interface ChatMessageEditedMessage extends Message {
  squadId: string
  chatId: string
  messageId: string
  message: any
}

interface ChatUserTyping extends Message {
  chatId: string
  user: { email: string; firstName: string; lastName: string }
  typing: boolean
}

interface OpenChatRequestMessage extends Message {
  chatId: string
}

interface ChatUserAddedMessage extends Message {
  squadId: string
  chat: Chat
}

interface ChatUserRemovedMessage extends Message {
  squadId: string
  chatId: string
}

interface ChatUsersUpdatedMessage extends Message {
  squadId: string
  chat: Chat
}

type PeerStatus = 'ONLINE' | 'OFFLINE'

interface PeerStatusMessage extends Message {
  peer: string
  squadId: string
  status: PeerStatus
}

interface ChatReactionAdded extends Message {
  chatId: string
  messageId: string
  author: {
    email: string
    firstName: string
    lastName: string
    organizationRole: OrganizationRole
  }
  reaction: string
  reactionId: string
}

interface ChatReactionDeleted extends Message {
  chatId: string
  messageId: string
  author: {
    email: string
    firstName: string
    lastName: string
    organizationRole: OrganizationRole
  }
  reaction: string
}

export const websocketSquadAction = createAction<SquadMessage>(
  'websocket-connection/squad',
)
export const websocketSquadNotExistAction = createAction<SquadNotExistMessage>(
  'websocket-connection/squad-not-exist',
)
export const websocketChatListAction = createAction<ChatListMessage>(
  'websocket-connection/chat-list',
)
export const websocketChatDetailsAction = createAction<ChatDetailsMessage>(
  'websocket-connection/chat-details',
)
export const websocketChatDetailsListAction = createAction<ChatDetailsListMessage>(
  'websocket-connection/chat-details-list',
)
export const websocketChatCreatedAction = createAction<ChatCreatedMessage>(
  'websocket-connection/chat-created',
)
export const websocketChatUpdatedAction = createAction<ChatUpdatedMessage>(
  'websocket-connection/chat-updated',
)
export const websocketChatArchivedAction = createAction<ChatArchivedMessage>(
  'websocket-connection/chat-archived',
)
export const websocketChatUnarchivedAction = createAction<ChatUnArchivedMessage>(
  'websocket-connection/chat-unarchived',
)
export const websocketChatMessagesAction = createAction<ChatMessagesMessage>(
  'websocket-connection/chat-messages',
)
export const websocketChatMessageReceivedAction =
  createAction<ChatMessageReceivedMessage>('websocket-connection/chat-message-received')
export const websocketChatMessageDeletedAction = createAction<ChatMessageDeletedMessage>(
  'websocket-connection/chat-message-deleted',
)
export const websocketChatMessageEditedAction = createAction<ChatMessageEditedMessage>(
  'websocket-connection/chat-message-edited',
)
export const websocketChatUserTypingAction = createAction<ChatUserTyping>(
  'websocket-connection/chat-user-typing',
)
export const websocketOpenChatRequestAction = createAction<OpenChatRequestMessage>(
  'websocket-connection/open-chat-request',
)
export const websocketChatUserAddedAction = createAction<ChatUserAddedMessage>(
  'websocket-connection/chat-user-added',
)
export const websocketChatUserRemovedAction = createAction<ChatUserRemovedMessage>(
  'websocket-connection/chat-user-removed',
)
export const websocketChatUsersUpdatedAction = createAction<ChatUsersUpdatedMessage>(
  'websocket-connection/chat-users-updated',
)
export const websocketPeerStatusAction = createAction<PeerStatusMessage>(
  'websocket-connection/peer-status',
)
export const webSocketChatReactionAdded = createAction<ChatReactionAdded>(
  'websocket-connection/chat-reactions-added',
)
export const webSocketChatReactionDeleted = createAction<ChatReactionDeleted>(
  'websocket-connection/chat-reactions-deleted',
)
export const webSocketChatMessagesSeenAction = createAction<ChatMessagesSeenMessage>(
  'websocket-connection/chat-messages-seen',
)

export class ChatWebsocketConnection extends WebsocketConnection {
  private _squadId: string

  constructor(dispatch: AppDispatch, jwt: string, me: string, squadId: string) {
    super(dispatch, jwt, me)
    this._squadId = squadId
  }

  public connect() {
    const url = `${process.env.REACT_APP_CHAT_BASE_WS_URL}?token=${this._jwt}&squad=${this._squadId}`
    super.connect(url)
  }

  public updateConnectionState(connected: boolean) {
    this._dispatch(setConnected(connected))
  }

  public handleMessage(data: Message) {
    switch (data.type) {
      case ChatMessageType.SQUAD:
        this._dispatch(websocketSquadAction(data as SquadMessage))
        break

      case ChatMessageType.SQUAD_NOT_EXIST:
        this._dispatch(websocketSquadNotExistAction(data as SquadNotExistMessage))
        break

      case ChatMessageType.CHAT_LIST:
        this._dispatch(websocketChatListAction(data as ChatListMessage))
        break

      case ChatMessageType.CHAT_DETAILS:
        this._dispatch(websocketChatDetailsAction(data as ChatDetailsMessage))
        break

      case ChatMessageType.CHAT_DETAILS_LIST:
        this._dispatch(websocketChatDetailsListAction(data as ChatDetailsListMessage))
        break

      case ChatMessageType.CHAT_CREATED:
        this._dispatch(websocketChatCreatedAction(data as ChatCreatedMessage))
        break

      case ChatMessageType.CHAT_UPDATED:
        this._dispatch(websocketChatUpdatedAction(data as ChatUpdatedMessage))
        break

      case ChatMessageType.CHAT_ARCHIVED:
        this._dispatch(websocketChatArchivedAction(data as ChatArchivedMessage))
        break

      case ChatMessageType.CHAT_UNARCHIVED:
        this._dispatch(websocketChatUnarchivedAction(data as ChatUnArchivedMessage))
        break

      case ChatMessageType.CHAT_MESSAGES:
        this._dispatch(websocketChatMessagesAction(data as ChatMessagesMessage))
        break

      case ChatMessageType.CHAT_MESSAGE_RECEIVED:
        this._dispatch(
          websocketChatMessageReceivedAction(data as ChatMessageReceivedMessage),
        )
        break

      case ChatMessageType.CHAT_MESSAGE_DELETED:
        this._dispatch(
          websocketChatMessageDeletedAction(data as ChatMessageDeletedMessage),
        )
        break

      case ChatMessageType.CHAT_MESSAGE_EDITED:
        this._dispatch(websocketChatMessageEditedAction(data as ChatMessageEditedMessage))
        break

      case ChatMessageType.CHAT_USER_TYPING:
        this._dispatch(websocketChatUserTypingAction(data as ChatUserTyping))
        break

      case ChatMessageType.CHAT_MESSAGE_REACTION_ADDED:
        this._dispatch(webSocketChatReactionAdded(data as ChatReactionAdded))
        break

      case ChatMessageType.CHAT_MESSAGE_REACTION_DELETED:
        this._dispatch(webSocketChatReactionDeleted(data as ChatReactionDeleted))
        break

      case ChatMessageType.OPEN_CHAT_REQUEST:
        this._dispatch(websocketOpenChatRequestAction(data as OpenChatRequestMessage))
        break

      case ChatMessageType.CHAT_USER_ADDED:
        this._dispatch(websocketChatUserAddedAction(data as ChatUserAddedMessage))
        break

      case ChatMessageType.CHAT_USER_REMOVED:
        this._dispatch(websocketChatUserRemovedAction(data as ChatUserRemovedMessage))
        break

      case ChatMessageType.CHAT_USERS_UPDATED:
        this._dispatch(websocketChatUsersUpdatedAction(data as ChatUsersUpdatedMessage))
        break

      case ChatMessageType.PEER_STATUS:
        this._dispatch(websocketPeerStatusAction(data as PeerStatusMessage))
        break

      case ChatMessageType.CHAT_MESSAGES_SEEN:
        this._dispatch(webSocketChatMessagesSeenAction(data as ChatMessagesSeenMessage))
        break

      case ChatMessageType.HEARTBEAT:
        this.send(
          JSON.stringify({
            type: 'HEARTBEAT_ACK',
          }),
        )
        break

      default:
        throw new Error('Unknown message type: ' + data.type)
    }
  }

  public sendGetSquad(squadId: string) {
    const data = JSON.stringify({
      type: ChatActions.GET_SQUAD,
      payload: { squadId },
    })

    this.send(data)
  }

  public sendCreateChat(
    title: string,
    squadId?: string,
    users?: string[],
    groups?: string[],
  ) {
    const data = JSON.stringify({
      type: ChatActions.CREATE_CHAT,
      payload: {
        title,
        squadId,
        users: JSON.stringify(users),
        groups: JSON.stringify(groups),
      },
    })
    this.send(data)
  }

  public sendListChats(squadId?: string, chatMeeting?: boolean) {
    const data = JSON.stringify({
      type: ChatActions.LIST_CHATS,
      payload: { squadId, chatMeeting },
    })

    this.send(data)
  }

  public sendFetchChatMessages(squadId: string, chatId: string, chunk?: number) {
    const data = JSON.stringify({
      type: ChatActions.FETCH_CHAT_MESSAGES,
      payload: { squadId, chatId, chunk },
    })

    this.send(data)
  }

  public sendFetchMessagesUntil(
    squadId: string,
    chatId: string,
    targetMessageId: string,
    oldestLoadedChunk: number,
  ) {
    const data = JSON.stringify({
      type: ChatActions.FETCH_CHAT_MESSAGES_UNTIL,
      payload: { squadId, chatId, targetMessageId, oldestLoadedChunk },
    })

    this.send(data)
  }

  public sendMarkChatMessagesSeen(squadId: string, chatId: string) {
    const data = JSON.stringify({
      type: ChatActions.MARK_CHAT_MESSAGES_SEEN,
      payload: { squadId, chatId },
    })

    this.send(data)
  }

  public sendChatMessage(
    squadId: string,
    chatId: string,
    text?: string,
    files?: string,
    repliedTo?: string,
  ) {
    const data = JSON.stringify({
      type: ChatActions.CREATE_CHAT_MESSAGE,
      payload: { squadId, chatId, text, files, repliedTo },
    })

    this.send(data)
  }

  public markChatMessageDeleted(
    squadId: string,
    chatId: string,
    messageId: string,
    deleted: boolean,
  ) {
    const data = JSON.stringify({
      type: ChatActions.MARK_CHAT_MESSAGE_DELETED,
      payload: { squadId, chatId, messageId, deleted },
    })

    this.send(data)
  }

  public editChatMessage(
    squadId: string,
    chatId: string,
    messageId: string,
    createdAt: string,
    text?: string,
    files?: string,
    repliedTo?: string,
  ) {
    //TODO: check if !text && !files before calling this method => throw an error. msg mustn't be empty
    const data = JSON.stringify({
      type: ChatActions.EDIT_CHAT_MESSAGE,
      payload: { squadId, chatId, messageId, createdAt, text, files, repliedTo },
    })

    this.send(data)
  }

  public sendUserTyping(chatId: string, isTyping: boolean) {
    const data = JSON.stringify({
      type: ChatActions.USER_TYPING,
      payload: { chatId, isTyping },
    })
    this.send(data)
  }

  public sendArchiveChat(chatId: string) {
    const data = JSON.stringify({
      type: ChatActions.ARCHIVE_CHAT,
      payload: {
        chatId,
      },
    })

    this.send(data)
  }

  public sendUpdateChatTitle(chatId: string, title: string) {
    const data = JSON.stringify({
      type: ChatActions.UPDATE_CHAT_TITLE,
      payload: {
        chatId,
        title,
      },
    })

    this.send(data)
  }

  public sendUpdateChatPeers(chatId: string, users: string[], groups: string[]) {
    const data = JSON.stringify({
      type: ChatActions.UPDATE_CHAT_PEERS,
      payload: {
        chatId,
        users: JSON.stringify(users),
        groups: JSON.stringify(groups),
      },
    })

    this.send(data)
  }

  public sendStartDial(to: string) {
    this.send(
      JSON.stringify({
        type: ChatActions.START_DIAL,
        payload: { to },
      }),
    )
  }

  public sendStopDial(id: string) {
    this.send(
      JSON.stringify({
        type: ChatActions.STOP_DIAL,
        payload: { id },
      }),
    )
  }

  public updateDisconnectTimeout() {
    clearTimeout(this.disconnectTimeout)

    this.disconnectTimeout = setTimeout(() => {
      this.connect()
    }, 5000)
  }

  public sendMessageReactionAdded(
    chatId: string,
    messageId: string,
    reactionContent: string,
    toDelete: boolean = false,
  ) {
    const data = JSON.stringify({
      type: ChatActions.CREATE_CHAT_MESSAGE_REACTION,
      payload: { chatId, messageId, reactionContent, toDelete },
    })

    this.send(data)
  }

  public sendMessageReactionRemoved(reactionId: string) {
    const data = JSON.stringify({
      type: ChatActions.REMOVE_CHAT_MESSAGE_REACTION,
      payload: { reactionId },
    })

    this.send(data)
  }
}
