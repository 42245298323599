import React from 'react'
import './ConnectionError.scss'

export default function ConnectionError() {
  return (
    <div className="ConnectionError">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 511.151 511.151"
      >
        <path
          fill="#f0506e"
          d="M393.818 223.575c-64.683 0-117.333 52.629-117.333 117.333s52.651 117.333 117.333 117.333 117.333-52.629 117.333-117.333S458.5 223.575 393.818 223.575zm0 213.334c-52.928 0-96-43.072-96-96s43.072-96 96-96 96 43.072 96 96-43.072 96-96 96z"
        />
        <path
          fill="#f0506e"
          d="M476.762 408.813L325.935 257.965c-4.16-4.16-10.923-4.16-15.083 0s-4.16 10.923 0 15.083l150.827 150.848a10.716 10.716 0 0 0 7.552 3.115c2.731 0 5.461-1.045 7.531-3.115 4.16-4.161 4.16-10.923 0-15.083z"
        />
        <path
          className="wifi-bar"
          fill="white"
          d="M443.524 153.261C384.73 88.536 306.458 52.909 223.151 52.909S61.572 88.536 2.778 153.261c-3.968 4.352-3.648 11.115.725 15.061 4.373 3.947 11.115 3.669 15.061-.725 54.72-60.203 127.36-93.355 204.587-93.355s149.867 33.152 204.587 93.355a10.617 10.617 0 0 0 7.893 3.499c2.56 0 5.141-.917 7.168-2.752 4.352-3.969 4.693-10.71.725-15.083z"
        />
        <path
          className="wifi-bar"
          fill="white"
          d="M361.818 188.546c-92.352-74.944-223.147-64.171-304.213 25.024-3.947 4.373-3.627 11.115.725 15.083a10.634 10.634 0 0 0 7.168 2.773c2.901 0 5.803-1.173 7.893-3.477 74.539-82.027 190.187-91.627 274.987-22.848 4.587 3.733 11.307 3.029 14.997-1.557 3.733-4.566 3.008-11.286-1.557-14.998z"
        />
        <path
          className="wifi-bar"
          fill="white"
          d="M283.311 235.714c-48.555-21.099-120.213-17.493-170.859 38.208-3.947 4.373-3.627 11.115.725 15.083a10.634 10.634 0 0 0 7.168 2.773c2.88 0 5.781-1.173 7.893-3.52 43.52-47.893 104.981-51.093 146.56-32.981 5.397 2.347 11.691-.149 14.037-5.525 2.348-5.398-.127-11.691-5.524-14.038zM263.684 388.311c-5.675-15.509-8.533-31.445-8.533-47.403 0-4.715.256-9.344.704-13.952a10.656 10.656 0 0 0-5.056-10.155c-8.469-5.163-18.027-7.893-27.648-7.893-29.397 0-53.333 23.936-53.333 53.333s23.936 53.333 53.333 53.333c14.464 0 28.011-5.739 38.165-16.149a10.68 10.68 0 0 0 2.368-11.114z"
        />
      </svg>
    </div>
  )
}
