import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Form, Input, Button, Select, Spin, Modal, Tooltip } from 'antd'
import {
  attemptAuth,
  attemptSafeaccessAuth,
  idleAttemptAuthStatus,
} from './redux/authSlice'
import { AppDispatch, RootState } from '../../store'
import { LanguageSwitch } from '../../components/LanguageSwitch'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './auth.scss'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { authLogoFilename, backgroundImageFilename } from '../../assets/personnalization'
import { useAttemptsListener, useSafeAccess } from 'auxasphere-react-kit'
import ModalResetPassword from './ResetPassword'
import { ModalConfirmationInterface } from '../../components/Modal/ModalConfirmation'
import ModalSignUp from './SignUp'
import packageInfo from '../../../package.json'
import { saQrCodeStyle } from '../../utils/Stylization'
import DigitalSovereigntyModal from '../../components/BadgesModal/DigitalSovereigntyModal'
import NotarisationModal from '../../components/BadgesModal/NotarisationModal'
//import ModalUnauthorizedAccess from '../../components/Modal/ModalUnauthorizedAccess'
import DevAuthSelector from './DevAuthSelector'
import { useToastContext } from '../../components/Toast/ToastContext'

function Auth() {
  const { i18n, t } = useTranslation('auth')
  const [saQrCode, setSaQrCode] = useState<string | undefined>()
  const [saWsClosed, setSaWsClosed] = useState(false)
  const [lockedLogin, setLockedLogin] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen, ToastDestroy } = useToastContext()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { roomId, page } = useParams()
  const jwt = useSelector((state: RootState) => state.auth.jwt)
  const attemptAuthStatus = useSelector(
    (state: RootState) => state.auth.attemptAuthStatus,
  )
  const hasBeenDisconnected = useSelector(
    (state: RootState) => state.auth.hasBeenDisconnected,
  )
  //const unAuthorizedAcces = useSelector((state: RootState) => state.app.isUnauthorized)

  const resetPasswordRef = useRef<ModalConfirmationInterface>(null)
  const signUpRef = useRef<ModalConfirmationInterface>(null)
  const [modalSignupNotAvailable, setModalSignupNotAvailable] = useState(false)
  const currentYear = new Date().getFullYear()
  const [openNotarisation, setOpenNotarisation] = useState(false)
  const [openSovereignty, setOpenSovereignty] = useState(false)
  const [saUrl, setSaUrl] = useState<string>('')

  useEffect(() => {
    if (jwt) {
      if (roomId) {
        if (page) {
          navigate(`/pending-meetings/${roomId}/${page}`)
        } else {
          navigate(`/room/${roomId}`)
        }
      } else {
        navigate('/applications')
      }
    }
  }, [jwt])

  useAttemptsListener(
    [
      [
        attemptAuthStatus,
        {
          PASSWORD_NOT_SET: () =>
            form.setFields([
              { name: 'email', errors: [t('Invalid login or password')] },
              { name: 'password', errors: [t('Invalid login or password')] },
            ]),
          INVALID_CREDENTIALS: () =>
            form.setFields([
              { name: 'email', errors: [t('Invalid login or password')] },
              { name: 'password', errors: [t('Invalid login or password')] },
            ]),
          NO_ORGANISATION: () =>
            ToastOpen({
              message: t(
                'Your account has been disabled because you are not belong to any organizations.',
              ),
              type: 'warning',
            }),
          TOO_MANY_REQUESTS: () =>
            ToastOpen({
              message: t('Too many attempts please try again in few minutes.'),
              type: 'warning',
            }),
          TOO_MANY_FAILED_ATTEMPTS: () =>
            ToastOpen({
              message: t('Too many failed attempts login deactivated for 5 minutes.'),
              type: 'warning',
            }),
          unknown_error: () =>
            ToastOpen({
              message: t('Unexpected error. Please try later.'),
              type: 'error',
            }),
        },
        () => dispatch(idleAttemptAuthStatus()),
      ],
    ],
    () => {
      if (process.env.REACT_APP_CREDENTIALS) {
        const CREDENTIALS = JSON.parse(process.env.REACT_APP_CREDENTIALS)
        form.setFieldsValue(CREDENTIALS)
      }
    },
  )

  const { saClientConnect, getQrCode } = useSafeAccess(
    {
      endpoint: process.env.REACT_APP_SAFEACCESS_BASE_URL + '/share-data',
      appName: process.env.REACT_APP_SAFEACCESS_APP || '',
      action: 'auth',
    },
    async (e) => {
      setSaUrl(e.URL)
      const qrCode = await getQrCode({
        data: e.URL,
        ...saQrCodeStyle,
      })
      setSaQrCode(qrCode)
      setSaWsClosed(false)
    },
    (e) => {
      dispatch(attemptSafeaccessAuth({ token: e.accessToken }))
    },
    () => {
      setSaQrCode(undefined)
      setSaWsClosed(true)
      saClientConnect()
    },
  )

  const openSecureAndAccess = () => {
    window.open(saUrl, '_blank')
  }

  const onFinish = (values: { email: string; password: string }) => {
    const trimmedEmail = values.email.trim()
    ToastDestroy('unauthorized')
    setLockedLogin(true)
    dispatch(
      attemptAuth({
        email: trimmedEmail,
        password: values.password,
        lang: i18n.language,
      }),
    )
    setTimeout(() => {
      // Can request login only once per second
      setLockedLogin(false)
    }, 1000)
  }

  return (
    <>
      <div
        className="Auth"
        style={{ backgroundImage: `url(/images/${backgroundImageFilename})` }}
      >
        <div className="documents-banner">
          <div>
            Auxasuite {currentYear} -&nbsp;
            <a
              className="footer-document"
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_STATIC_ASSETS_BASE_URL}/legal-notice_${i18n.language}.pdf`}
            >
              {t('Legal notice', { ns: 'common' })}
            </a>
          </div>
          <div className="badges-container">
            <div
              className="sovereignty-container"
              onClick={() => setOpenSovereignty(true)}
            >
              <img src={`/images/sovereignty_${i18n.language}.png`} />
            </div>
            <div
              className="notarisation-container"
              onClick={() => setOpenNotarisation(true)}
            >
              <img src={`/images/notarisation_${i18n.language}.png`} />
            </div>
          </div>
        </div>
        <div className="layer" />
        <Tooltip
          title={`${t('Version:', { ns: 'common' })} ${packageInfo.version}`}
          placement="bottom"
        >
          <Link to="/" className="logo-container">
            <img className="logo" src={`/logo/${authLogoFilename}`} />
          </Link>
        </Tooltip>
        <div className="auth-methods">
          <div className="auth-methods-login-password">
            {process.env.REACT_APP_ENV == 'LOCAL' && (
              <DevAuthSelector onSelected={onFinish} />
            )}
            <Form
              form={form}
              name="basic"
              layout="vertical"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ email: '', password: '' }}
              onFinish={onFinish}
              autoComplete="off"
              scrollToFirstError={true}
            >
              <Form.Item
                label={<span className="login-label">{t('Email')}</span>}
                name="email"
                rules={[
                  { required: true, message: t('Please input your email') },
                  {
                    type: 'email',
                    message: t('The format of the email does not comply', {
                      ns: 'common',
                    }),
                    validateTrigger: 'onSubmit',
                    transform: (value) => value.trim(),
                  },
                ]}
              >
                <Input prefix={<MailOutlined className="prefix-label-icon" />} />
              </Form.Item>

              <Form.Item
                label={<span className="login-label">{t('Password')}</span>}
                name="password"
                rules={[{ required: true, message: t('Please input your password') }]}
              >
                <Input.Password prefix={<LockOutlined className="prefix-label-icon" />} />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                block
                loading={attemptAuthStatus === 'loading'}
                disabled={lockedLogin}
              >
                {t('Log in')}
              </Button>
            </Form>
            <div className="auth-misc-container">
              <Button type="text" onClick={() => resetPasswordRef.current?.openModal()}>
                {t('Forgot your password?')}
              </Button>
              {process.env.REACT_APP_LIMITE_SIGN_UP ? (
                <Button type="text" onClick={() => setModalSignupNotAvailable(true)}>
                  {t('Create your account')}
                </Button>
              ) : (
                <Button type="text" onClick={() => signUpRef.current?.openModal()}>
                  {t('Create your account')}
                </Button>
              )}
            </div>
            <div className="auth-methods-secure_and_access-phone">
              <p className="secure-text">
                {t('Secure your connections with Secure & Access')}
              </p>
              <Button type="primary" onClick={openSecureAndAccess} block>
                {t('Open Secure & Access')}
              </Button>
            </div>
          </div>

          <div className="auth-methods-separator" />
          <div className="auth-methods-secure_and_access">
            <div className="auth-methods-language">
              <LanguageSwitch />
            </div>
            <div className="qr-code">
              {saWsClosed && (
                <Button onClick={saClientConnect}>
                  {t('Reconnect to Secure & Access')}
                </Button>
              )}
              {saQrCode ? (
                <img src={saQrCode} alt="Secure & Access qr-code" />
              ) : (
                <div className="qr-code-placeholder">
                  <Spin />
                </div>
              )}
              <Button
                className="auth-methods-secure_and_access-button"
                type="primary"
                onClick={openSecureAndAccess}
                block
              >
                {t('Open Secure & Access')}
              </Button>
              {saQrCode && (
                <p className="secure-text">
                  {t('Secure your connections with Secure & Access')}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        centered
        title={t('Create your account')}
        open={modalSignupNotAvailable}
        onCancel={() => setModalSignupNotAvailable(false)}
        footer={
          <div>
            <Button onClick={() => setModalSignupNotAvailable(false)}>
              {t('Close', { ns: 'common' })}
            </Button>
          </div>
        }
      >
        <p>
          {t(
            'This feature is not available, please contact your organization administrator to create your account.',
          )}
        </p>
      </Modal>
      <ModalResetPassword ref={resetPasswordRef} />
      <ModalSignUp ref={signUpRef} />
      <NotarisationModal
        openNotarisation={openNotarisation}
        setOpenNotarisation={setOpenNotarisation}
      />

      <DigitalSovereigntyModal
        openSovereignty={openSovereignty}
        setOpenSovereignty={setOpenSovereignty}
      />
      {/* <ModalUnauthorizedAccess unAuthorizedAcces={unAuthorizedAcces} /> */}
    </>
  )
}

export default Auth
