import React, { useEffect, useState } from 'react'
import {
  Button,
  Dropdown,
  Form,
  Input,
  Select,
  Switch,
  Table,
  Tooltip,
  Pagination,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import Modal from 'antd/lib/modal/Modal'
import {
  attemptDeleteParticipant,
  attemptModifyParticipant,
  fetchOrganizationSummary,
  fetchUsers,
  idleDeleteParticipantStatus,
  idleModifyParticipantStatus,
  idleUsers,
} from './redux/organisationSlice'
import { OrganizationParticipant, OrganizationRole } from './OrganizationInterface'
import './Organisation.scss'
import { IoTrashOutline } from 'react-icons/io5'
import { Trans, useTranslation } from 'react-i18next'
import { AiOutlineEdit, AiOutlineEye } from 'react-icons/ai'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { MenuProps } from 'antd/lib'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { useToastContext } from '../../components/Toast/ToastContext'
import { capitalizeFirstLetter } from '../../utils/Utils'
import { SorterResult } from 'antd/es/table/interface'
import useParticipantsTableSorter from '../../utils/hooks/useParticipantsTableSorter'

function OrganisationTable() {
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const { t } = useTranslation('organisation')
  const email = useSelector((state: RootState) => state.auth.email)
  const isAdmin = useSelector(
    (state: RootState) => state.auth.organizationRole === OrganizationRole.ADMIN,
  )
  const organisation = useSelector((state: RootState) => state.organisation)

  const fetchUsersStatus = useSelector(
    (state: RootState) => state.organisation.fetchUsersStatus,
  )
  const [participantToDelete, setParticipantToDelete] =
    useState<OrganizationParticipant>()

  const [participantToModify, setParticipantToModify] =
    useState<OrganizationParticipant>()
  const [viewOnly, setViewOnly] = useState<boolean>(false)

  const [modifyParticipantForm] = Form.useForm()
  const [role, setRole] = useState<OrganizationRole>(OrganizationRole.USER)

  const disabled = !isAdmin || viewOnly

  const { sorter, setSorter, pageDetails, setPageDetails, updateTable } =
    useParticipantsTableSorter({ page: 1, usersPerPage: 20 })

  /**
   *
   */
  useEffect(() => {
    if (organisation.organisation) {
      dispatch(fetchOrganizationSummary({ organizationId: organisation.organisation.id }))
    }
  }, [pageDetails])

  /**
   *
   */
  useEffect(() => {
    if (organisation.organisation?.id)
      dispatch(
        fetchOrganizationSummary({ organizationId: organisation.organisation?.id }),
      )
  }, [])

  useAttemptsListener([
    [
      organisation.deleteParticipantStatus,
      {
        error: () => {
          ToastOpen({
            message: t('An error occurred.'),
            type: 'error',
          })
        },
        success: () => {
          // Check if the current page is greater than the total number of pages after removing one user
          if (
            pageDetails.page >
            Math.ceil((organisation.usersTotalNumber - 1) / pageDetails.usersPerPage)
          ) {
            setPageDetails({
              page: pageDetails.page - 1,
              usersPerPage: pageDetails.usersPerPage,
            })
          }
          updateTable()
          ToastOpen({
            message: t('The user has been successfully deleted.'),
            type: 'success',
          })
          setParticipantToDelete(undefined)
        },
      },
      () => dispatch(idleDeleteParticipantStatus()),
    ],
    [
      organisation.modifyParticipantStatus,
      {
        error: () => {
          ToastOpen({
            message: t('An error occurred.'),
            type: 'error',
          })
        },
        success: () => {
          updateTable()
          ToastOpen({
            message: t('The user has been successfully modified.'),
            type: 'success',
          })
          setParticipantToModify(undefined)
        },
      },
      () => dispatch(idleModifyParticipantStatus()),
    ],
  ])

  const handleRoleChange = (value: OrganizationRole) => {
    setRole(value)
  }

  const handleSorterChange = (
    pagination: any,
    filters: any,
    sorter:
      | SorterResult<OrganizationParticipant>
      | SorterResult<OrganizationParticipant>[],
    extra: any,
  ) => {
    if (!Array.isArray(sorter)) {
      console.log('sorter', sorter)
      const sortOrder =
        sorter.order === 'ascend' ? 'ASC' : sorter.order === 'descend' ? 'DESC' : ''
      const sortField = sorter.field ? String(sorter.field) : ''
      console.log('field:', sortField, 'Sort order:', sortOrder)
      setSorter({ sortField, sortOrder })
    }
  }
  /**
   *
   */
  useEffect(() => {
    modifyParticipantForm.setFieldsValue({
      email: participantToModify?.email,
      firstName: participantToModify?.firstName,
      lastName:
        participantToModify?.organizationRole === OrganizationRole.EXTERNAL
          ? participantToModify?.lastName?.slice(0, -6)
          : participantToModify?.lastName, //Erase (EXT) //TEmporary solution
      lang: participantToModify?.lang,
      organizationRole: participantToModify?.organizationRole,
      accessTeam: participantToModify?.accessTeam,
      accessMeetings: participantToModify?.accessMeetings,
      accessPlanning: participantToModify?.accessPlanning,
      accessDrive: participantToModify?.accessDrive,
      accessOrganization: participantToModify?.accessOrganization,
    })
    setRole(participantToModify?.organizationRole ?? OrganizationRole.USER)
  }, [participantToModify])

  /**
   *
   */
  function deleteParticipant() {
    if (!organisation.organisation?.id) {
      throw new Error(
        'something went wrong: organisation.id = ' + organisation.organisation?.id,
      )
    }
    if (participantToDelete) {
      dispatch(
        attemptDeleteParticipant({
          organizationId: organisation.organisation.id,
          user: { email: participantToDelete.email },
        }),
      )
    }
  }

  /**
   *
   * @param values
   * @returns
   */
  function modifyParticipant(values: any) {
    if (!organisation.organisation?.id || !participantToModify) return
    dispatch(
      attemptModifyParticipant({
        organizationId: organisation.organisation.id,
        originalEmail: participantToModify.email,
        user: {
          email: values['email'].trim(),
          firstName: values['firstName'],
          lastName: values['lastName'],
          lang: values['lang'],
          groups: values['groups'] || [],
          organizationRole: values['organizationRole'],
          accessTeam: values['accessTeam'] || false,
          accessMeetings: values['accessMeetings'] || false,
          accessPlanning: values['accessPlanning'] || false,
          accessDrive: values['accessDrive'] || false,
          accessOrganization: values['accessOrganization'] || false,
        },
      }),
    )
  }

  /**
   *
   */
  function closeModifyParticipantModal() {
    setParticipantToModify(undefined)
    setViewOnly(false)
  }

  /**
   *
   * @param p
   * @returns
   */
  const menuItems = (p: OrganizationParticipant) => {
    var menu: MenuProps['items'] = []
    menu.push({
      key: 'view',
      label: (
        <div className="cursor-pointer link d-flex d-flex-middle">
          <AiOutlineEye size="1.1rem" />
          <div className="dropdown-icon-margin-left">{t('View user')}</div>
        </div>
      ),
      onClick: () => {
        setParticipantToModify(p)
        setViewOnly(true)
      },
    })
    menu.push(
      {
        key: 'edit',
        label: (
          <div className="cursor-pointer link d-flex d-flex-middle">
            <AiOutlineEdit size="1.1rem" />
            <div className="dropdown-icon-margin-left">{t('Modify user')}</div>
          </div>
        ),
        onClick: () => setParticipantToModify(p),
      },
      {
        type: 'divider',
      },
      {
        key: 'delete',
        label: (
          <div className="cursor-pointer d-flex d-flex-middle bin-button">
            <IoTrashOutline size="1.1rem" className="error-color" />
            <div className="dropdown-icon-margin-left error-color">
              {t('DELETE_PARTICIPANT_TITLE')}
            </div>
          </div>
        ),
        onClick: () => setParticipantToDelete(p),
      },
    )
    return menu
  }

  const commonColumns = [
    {
      title: t('Full name', { ns: 'common' }),
      className: 'full-name-column',
      render: (p: OrganizationParticipant) => {
        return <>{`${p.firstName} ${p.lastName}`}</>
      },
      key: 'last_name',
      width: '40%',
      sorter: true,
    },
    {
      title: t('Email'),
      className: 'name-column',
      dataIndex: 'email',
      key: 'email',
      width: '40%',
      sorter: true,
    },
  ]

  const adminColumns = [
    {
      title: t('Role', { ns: 'common' }),
      render: (p: OrganizationParticipant) => {
        return (
          <div className="h-1_35">
            {t(`${capitalizeFirstLetter(p.organizationRole.toString())}`, {
              ns: 'common',
            })}
          </div>
        )
      },
      width: '5%',
    },
    {
      title: '',
      className: 'action-column',
      render: (p: OrganizationParticipant) => {
        return (
          email !== p.email && (
            <div className="action-main-container">
              <div className="action-custom-container">
                <div className="action-hover-container">
                  <div className="action-icons">
                    <div
                      className="action-rounded-icon"
                      onClick={() => {
                        setParticipantToModify(p)
                        setViewOnly(true)
                      }}
                    >
                      <Tooltip title={t('View user')} className="cursor-pointer">
                        <AiOutlineEye size="1.5rem" />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              <Dropdown
                menu={{ items: menuItems(p) }}
                className="action-more"
                trigger={['click']}
              >
                <div className="action-more-container">
                  <div className="action-more">
                    <BiDotsVerticalRounded size="1.5em" />
                  </div>
                </div>
              </Dropdown>

              <div className="action-more-mobile">
                <Button onClick={() => setParticipantToModify(p)}>
                  <AiOutlineEdit size="1.5rem" />
                </Button>
                <Button
                  className="btn-danger-border"
                  onClick={() => setParticipantToDelete(p)}
                >
                  <IoTrashOutline size="1.1rem" className="error-color" />
                </Button>
              </div>
            </div>
          )
        )
      },
      width: '15%',
    },
  ]
  const columns = isAdmin ? [...commonColumns, ...adminColumns] : commonColumns

  return (
    <>
      {fetchUsersStatus === 'loading' && (
        <div className="d-flex d-flex-center d-flex-middle 2rem">
          <Spin size="large" />
        </div>
      )}
      {fetchUsersStatus === 'success' && (
        <div className="organisation-table-container">
          <Table
            style={{ width: '100%', marginBottom: '1em' }}
            dataSource={organisation.users}
            rowKey="email"
            key="email"
            columns={columns}
            pagination={false}
            onChange={handleSorterChange}
          />
          {organisation.usersTotalNumber > 2 && (
            <Pagination
              showTotal={(total, range) => (
                <Trans
                  ns="common"
                  i18nKey="TABLE_PAGINATION"
                  values={{
                    range0: range[0],
                    range1: range[1],
                    total: total,
                  }}
                />
              )}
              onChange={(page, usersPerPage) => setPageDetails({ page, usersPerPage })}
              onShowSizeChange={(page, usersPerPage) =>
                setPageDetails({ page, usersPerPage })
              }
              current={pageDetails.page}
              pageSize={pageDetails.usersPerPage}
              total={organisation.usersTotalNumber}
              showSizeChanger={true}
            />
          )}
        </div>
      )}
      <Modal
        centered
        title={
          <Trans
            i18nKey="DELETE_PARTICIPANT_TITLE"
            shouldUnescape={true}
            ns="organisation"
            values={{ email: participantToDelete?.email }}
          />
        }
        open={participantToDelete != undefined}
        onCancel={() => setParticipantToDelete(undefined)}
        footer={
          <div className="signup-modal-footer">
            <Button onClick={() => setParticipantToDelete(undefined)}>
              {t('Cancel', { ns: 'common' })}
            </Button>
            <Button
              type="primary"
              onClick={deleteParticipant}
              loading={organisation.deleteParticipantStatus === 'loading'}
            >
              {t('Delete', { ns: 'common' })}
            </Button>
          </div>
        }
      >
        <p>
          <Trans
            i18nKey="DELETE_PARTICIPANT"
            shouldUnescape={true}
            ns="organisation"
            values={{
              firstName: participantToDelete?.firstName,
              lastName: participantToDelete?.lastName,
              email: participantToDelete?.email,
            }}
            components={{ bold: <strong /> }}
          />
        </p>
      </Modal>

      <Modal
        centered
        title={disabled ? t('View user') : t('Modify user')}
        open={participantToModify != undefined}
        onCancel={closeModifyParticipantModal}
        onOk={() => modifyParticipantForm.submit()}
        okText={t('Modify user')}
        cancelText={
          disabled ? t('Close', { ns: 'common' }) : t('Cancel', { ns: 'common' })
        }
        okButtonProps={disabled ? { style: { display: 'none' } } : {}}
        styles={{ body: { padding: '1em 2em' } }}
      >
        <Form
          form={modifyParticipantForm}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={modifyParticipant}
          scrollToFirstError={true}
        >
          <Form.Item
            label={t('Email')}
            name="email"
            rules={[{ required: true, message: t('Please input an email') }]}
          >
            <Input disabled={true} />
          </Form.Item>

          <Form.Item
            label={t('First name')}
            name="firstName"
            rules={[{ required: true, message: t('Please input a first name') }]}
          >
            <Input disabled={disabled} />
          </Form.Item>

          <Form.Item
            label={t('Last name')}
            name="lastName"
            rules={[{ required: true, message: t('Please input a last name') }]}
          >
            <Input disabled={disabled} />
          </Form.Item>

          <Form.Item
            label={t('Preferred language')}
            name="lang"
            rules={[{ required: true, message: t('Please select a preferred language') }]}
          >
            <Select disabled={disabled}>
              <Select.Option value="de">Deutsch</Select.Option>
              <Select.Option value="en">English</Select.Option>
              <Select.Option value="fr">Français</Select.Option>
              <Select.Option value="it">Italiano</Select.Option>
              <Select.Option value="uk">Українська</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={t('Role', { ns: 'common' })}
            name="organizationRole"
            rules={[{ required: true }]}
          >
            <Select onChange={handleRoleChange} disabled={disabled}>
              <Select.Option value={OrganizationRole.ADMIN}>
                {t('Admin', { ns: 'common' })}
              </Select.Option>
              <Select.Option value={OrganizationRole.USER}>
                {t('User', { ns: 'common' })}
              </Select.Option>
              <Select.Option value={OrganizationRole.EXTERNAL}>
                {t('External', { ns: 'common' })}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={t('Access Team')} name="accessTeam" valuePropName="checked">
            <Switch />
          </Form.Item>

          <Form.Item
            label={t('Access Meetings')}
            name="accessMeetings"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label={t('Access Planning')}
            name="accessPlanning"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item label={t('Access Drive')} name="accessDrive" valuePropName="checked">
            <Switch />
          </Form.Item>

          <Form.Item
            label={t('Access Organization')}
            name="accessOrganization"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default OrganisationTable
