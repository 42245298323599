import React, { useEffect } from 'react'
import { Badge, Button, Menu, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  AiOutlineClockCircle,
  AiOutlineInbox,
  AiOutlineUnorderedList,
  AiOutlineVideoCameraAdd,
} from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import './meetings.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  idlePeriodicRoomsCountStatus,
  idleRoomsCountStatus,
  periodicRoomsCount,
  roomsCount,
} from './MeetingsSlice'
import dayjs from 'dayjs'
import { IoAddCircle } from 'react-icons/io5'

function MeetingsMenu() {
  const navigate = useNavigate()
  const { t } = useTranslation('layout')
  const dispatch = useDispatch<AppDispatch>()
  const pendingCount = useSelector((state: RootState) => state.rooms.roomsCount?.pending)
  const upcomingCount = useSelector(
    (state: RootState) => state.rooms.roomsCount?.upcoming,
  )
  const periodicUpcomingCount = useSelector(
    (state: RootState) => state.rooms.periodicUpcomingCount,
  )
  const maxDate = dayjs()
    .add(parseInt(process.env.REACT_APP_MAX_DATE_UPCOMING || '30'), 'days')
    .toISOString()
  const access = useSelector((state: RootState) => state.auth.access)

  useEffect(() => {
    dispatch(roomsCount({}))
    return () => {
      dispatch(idleRoomsCountStatus())
    }
  }, [])

  useEffect(() => {
    dispatch(periodicRoomsCount({ minDate: dayjs().toISOString(), maxDate: maxDate }))
    return () => {
      dispatch(idlePeriodicRoomsCountStatus())
    }
  }, [])

  const items: any = [
    {
      key: 'new-meeting',
      label: (
        <Tooltip
          title={
            !access?.organization
              ? t('You do not have the necessary rights.', { ns: 'common' })
              : ''
          }
        >
          <label>{t('New meeting')}</label>
        </Tooltip>
      ),
      disabled: !access?.organization,
      icon: <AiOutlineVideoCameraAdd />,
      onClick: () => access?.organization && navigate('/new-meeting'),
    },
    {
      key: 'upcoming-meetings',
      label: (
        <div className="meetings-label">
          {t('Upcoming meetings')}
          <Badge count={(upcomingCount || 0) + (periodicUpcomingCount || 0)} />
        </div>
      ),
      icon: <AiOutlineUnorderedList />,
      onClick: () => navigate('/upcoming-meetings'),
    },
    {
      key: 'pending-meetings',
      label: (
        <div className="meetings-label">
          {t('Pending meetings')}
          <Badge
            count={pendingCount}
            className={`${location.pathname.includes('pending-meetings')}`}
          />
        </div>
      ),
      icon: <AiOutlineClockCircle />,
      onClick: () => navigate('/pending-meetings'),
    },
    {
      key: 'archived-meetings',
      label: t('Archived meetings'),
      icon: <AiOutlineInbox />,
      onClick: () => navigate('/archived-meetings'),
    },
  ]

  return (
    <>
      <div className="menu-action-btn-container">
        <Tooltip
          title={
            !access?.organization
              ? t('You do not have the necessary rights.', { ns: 'common' })
              : ''
          }
        >
          <Button
            className="menu-action-btn"
            type="primary"
            disabled={!access?.organization}
            icon={<IoAddCircle size="1.5rem" color="white" />}
            onClick={() => navigate('/new-meeting')}
          >
            {t('Create meeting', { ns: 'meetings' })}
          </Button>
        </Tooltip>
      </div>
      <Menu
        className="menu"
        mode="inline"
        selectedKeys={
          (location.pathname === '/new-meeting' && ['new-meeting']) ||
          (location.pathname.includes('upcoming-meetings') && ['upcoming-meetings']) ||
          (location.pathname.includes('pending-meetings') && ['pending-meetings']) ||
          (location.pathname.includes('archived-meetings') && ['archived-meetings']) ||
          []
        }
        items={items}
      />
    </>
  )
}

export default MeetingsMenu
