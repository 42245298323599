import React, { useEffect } from 'react'
import { Navigate, BrowserRouter, Routes, Route } from 'react-router-dom'
import Auth from './features/auth/Auth'
import RequireAuth from './features/auth/RequireAuth'
import Account from './features/account/Account'
import Room from './features/room/Room'
import NotFound from './features/NotFound/NotFound'
import Invite from './features/invite/Invite'
import Organisation from './features/Organisation/Organisation'
import ReinitPassword from './features/ReinitPassword/ReinitPassword'
import { useTranslation } from 'react-i18next'
import Homepage from './features/Homepage/Homepage'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from './store'
import { setIsPhone } from './redux/appSlice'
import './features/room/RoomClient' // Static methods
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import PersonalizationPreview from './features/Personalization/Preview'
import ArchivedMeetings from './features/Meetings/ArchivedMeetings/ArchivedMeetings'
import UpcomingMeetings from './features/Meetings/UpcomingMeetings/UpcomingMeetings'
import NewMeeting from './features/Meetings/NewMeeting/NewMeeting'
import RoomRequireAuth from './features/room/RoomRequireAuth'
import InvitedMeetingVote from './features/Meetings/MeetingVote/InvitedMeetingVote'
import Messages from './features/Messages/Messages'
import Notarisation from './features/Notarisation/Notarisation'
import Calendar from './features/calendar/CalendarComponent'
import { LocaleType, resources, antLocale } from './i18n'
import { ConfigProvider, notification } from 'antd'
import PendingMeetings from './features/Meetings/PendingMeetings/PendingMeetings'
import FAQ from './features/FAQ/FAQ'
import HelpNetwork from './features/FAQ/pages/Network'
import HelpDevices from './features/FAQ/pages/Devices'
import Groups from './features/Groups/Groups'
import Drives from './features/Drives/Drives'
import { attemptCheckPersonalizationAvailable } from './features/Personalization/PersonalizationSlice'
import Applications from './features/applications/Applications'
import { HelmetProvider } from 'react-helmet-async'
import DisconnectAFK from './features/auth/DisconnectAFK'
import Notifications from './features/Notifications/Notifications'
import ToastProvider from './components/Toast/ToastContext'
import SessionExpiration from './components/SessionExpiration'
import DriveProvider from './features/Drives/DrivesContext'
import { OrganizationRole } from './features/Organisation/OrganizationInterface'
import RoomConnectionError from './features/room/RoomConnectionError'
import Maintenance from './features/maintenance/Maintenance'

function App() {
  const { i18n } = useTranslation('app')
  const dispatch = useDispatch<AppDispatch>()
  dayjs.extend(LocalizedFormat)
  dayjs.locale(i18n.language)

  useEffect(() => {
    dispatch(setIsPhone())
  }, [])

  const [api, contextHolder] = notification.useNotification({
    stack: false,
    maxCount: 4,
  })

  const personalizationAvailable = useSelector(
    (state: RootState) => state.personalization.available,
  )
  const isAdmin = useSelector(
    (state: RootState) => state.auth.organizationRole === OrganizationRole.ADMIN,
  )
  const jwt = useSelector((state: RootState) => state.auth.jwt || state.invite.jwt || '')
  const access = useSelector((state: RootState) => state.auth.access)
  const invited = useSelector((state: RootState) => state.invite.jwt !== undefined)

  useEffect(() => {
    if (jwt) {
      dispatch(attemptCheckPersonalizationAvailable())
    }
  }, [jwt])

  return (
    <HelmetProvider>
      <div className="App">
        {contextHolder}
        <ToastProvider api={api}>
          <SessionExpiration />
          <DisconnectAFK />
          <ConfigProvider
            locale={
              antLocale[
                Object.keys(resources).includes(i18n.language)
                  ? (i18n.language as LocaleType)
                  : 'en'
              ]
            }
          >
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Navigate to="/applications" />} />
                <Route path="/homepage" element={<Homepage />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/faq/network" element={<HelpNetwork />} />
                <Route path="/faq/devices" element={<HelpDevices />} />
                <Route path="/login/:roomId?/:page?" element={<Auth />} />
                <Route path="/reinit-password/:token" element={<ReinitPassword />} />
                <Route path="/init-password/:token" element={<ReinitPassword />} />

                {(access?.meetings || invited || !jwt) && (
                  <>
                    <Route
                      path="/room/:roomId"
                      element={
                        <RoomRequireAuth>
                          <Room />
                        </RoomRequireAuth>
                      }
                    />
                    <Route
                      path="/room/error-connection/:roomId"
                      element={<RoomConnectionError />}
                    />
                  </>
                )}

                {access?.meetings && (
                  <>
                    {access?.organization && (
                      <Route
                        path="/new-meeting"
                        element={
                          <RequireAuth>
                            <NewMeeting />
                          </RequireAuth>
                        }
                      />
                    )}
                    <Route
                      path="/upcoming-meetings"
                      element={
                        <RequireAuth>
                          <UpcomingMeetings />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/pending-meetings"
                      element={
                        <RequireAuth>
                          <PendingMeetings />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/pending-meetings/:roomId/:page"
                      element={
                        <RoomRequireAuth>
                          <PendingMeetings />
                        </RoomRequireAuth>
                      }
                    />
                    <Route
                      path="/archived-meetings"
                      element={
                        <RequireAuth>
                          <ArchivedMeetings />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/vote/:roomId"
                      element={
                        <RequireAuth invitedAccepted={true}>
                          <InvitedMeetingVote />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/upcoming-meetings/notarisation/:roomId"
                      element={
                        <RoomRequireAuth>
                          <Notarisation />
                        </RoomRequireAuth>
                      }
                    />
                    <Route
                      path="/archived-meetings/notarisation/:roomId"
                      element={
                        <RoomRequireAuth>
                          <Notarisation />
                        </RoomRequireAuth>
                      }
                    />
                  </>
                )}

                <Route
                  path="/account"
                  element={
                    <RequireAuth>
                      <Account />
                    </RequireAuth>
                  }
                />
                {access?.organization && (
                  <>
                    <Route
                      path="/organization"
                      element={
                        <RequireAuth>
                          <Organisation />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/groups"
                      element={
                        <RequireAuth>
                          <Groups />
                        </RequireAuth>
                      }
                    />
                  </>
                )}

                {access?.drive && (
                  <>
                    <Route
                      path="/drives/home"
                      element={
                        <RequireAuth>
                          <DriveProvider>
                            <Drives />
                          </DriveProvider>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/drives/my-drives"
                      element={
                        <RequireAuth>
                          <DriveProvider>
                            <Drives />
                          </DriveProvider>
                        </RequireAuth>
                      }
                    />
                  </>
                )}

                {access?.team && (
                  <Route
                    path="/messages"
                    element={
                      <RoomRequireAuth>
                        <Messages />
                      </RoomRequireAuth>
                    }
                  />
                )}

                {access?.planning && (
                  <Route
                    path="/calendar"
                    element={
                      <RequireAuth>
                        <Calendar />
                      </RequireAuth>
                    }
                  />
                )}

                <Route
                  path="/applications"
                  element={
                    <RequireAuth>
                      <Applications />
                    </RequireAuth>
                  }
                />
                {personalizationAvailable && isAdmin && (
                  <Route
                    path="/personalization/preview"
                    element={<PersonalizationPreview />}
                  />
                )}

                <Route path="/invite/meeting/:token" element={<Invite />} />
                <Route path="/invite/:token/:action" element={<Invite />} />
                <Route
                  path="/notifications"
                  element={
                    <RequireAuth>
                      <Notifications />
                    </RequireAuth>
                  }
                />
                <Route path="/ongoing-maintenance" element={<Maintenance />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </ConfigProvider>
        </ToastProvider>
      </div>
    </HelmetProvider>
  )
}

export default App
