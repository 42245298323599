import React from 'react'
import { FolderInterface } from '../../features/Drives/redux/drivesSlice'
import { Card, Popover, Button } from 'antd'
import './DriveCard.scss'
import { MdOutlineFolderShared } from 'react-icons/md'
import { AiOutlineFolder, AiOutlineInfoCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { useDrivesContext } from '../../features/Drives/DrivesContext'
import DriveRightsIcon from './DriveRightsIcon'

interface Props {
  driveRootFolder: FolderInterface
}

/**
 *
 */
function DriveCard({ driveRootFolder }: Props) {
  const { t } = useTranslation('drives')
  const { fetchAndSelectFolder } = useDrivesContext()

  /**
   * Handle drive selection
   */
  function onSelectDrive() {
    fetchAndSelectFolder(driveRootFolder)
  }

  /**
   * Handle opening a folder
   */
  function onOpenFolder(folder: FolderInterface) {
    fetchAndSelectFolder(folder)
  }

  const popoverContent = (
    <div className="child-folders-popover">
      {driveRootFolder.childrenFolders.length > 0 ? (
        driveRootFolder.childrenFolders.map((folder) => (
          <div
            key={folder.id}
            className="child-folder"
            onClick={() => onOpenFolder(folder)}
          >
            <AiOutlineFolder size="1.5em" />
            <span>{folder.name}</span>
          </div>
        ))
      ) : (
        <span>{t('No subfolders')}</span>
      )}
    </div>
  )

  return (
    <Card className="DriveCard" hoverable onDoubleClick={onSelectDrive}>
      <div className="container">
        <div className="title-container">
          <div className="icon-container">
            <MdOutlineFolderShared size="1.6em" />
          </div>
          <h4 className="title">{driveRootFolder.name}</h4>
        </div>
        <div>
          <div className="actions-container">
            <Popover title={t('Subfolders')} content={popoverContent} trigger="click">
              <Button
                type="text"
                icon={<AiOutlineInfoCircle size="1.3em" className="mt-0_2rem" />}
              />
            </Popover>
            <DriveRightsIcon driveId={driveRootFolder.driveId} />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default DriveCard
