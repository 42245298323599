import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { AppDispatch, RootState } from '../../store'
import RoomClient from './RoomClient'
import Peers from './Peers'
import './Room.scss'
import { BsChevronDown, BsFillCheckSquareFill, BsFillXSquareFill } from 'react-icons/bs'
import {
  Alert,
  Button,
  Col,
  Collapse,
  Drawer,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
} from 'antd'
import { clearInvite } from '../invite/redux/inviteSlice'
import { Trans, useTranslation } from 'react-i18next'
import DocumentsList from '../../components/DocumentsList/DocumentsList'
import { useAttemptsListener } from 'auxasphere-react-kit'
import {
  fetchAccessToken,
  clearAccessToken,
  clearMeeting,
  setShowInfo,
} from './redux/roomSlice'
import DocumentsUpload from '../../components/DocumentsUpload/DocumentsUpload'
import Antechamber from './Antechamber/Antechamber'
import { AiOutlineMail } from 'react-icons/ai'
import { FiRefreshCcw } from 'react-icons/fi'
import { LanguageSwitch } from '../../components/LanguageSwitch'
import RoomParticipant from './RoomParticipantDelete/RoomParticipantDelete'
import classNames from 'classnames'
import {
  addInvited,
  addParticipant,
  deleteInvited,
  deleteParticipant,
  updateParticipantRole,
  updateInvitedRole,
  idleNotifyMissingParticipantsStatus,
  notifyMissingParticipants,
} from '../Meetings/MeetingsSlice'
import CallControls from '../../components/CallControls/CallControls'
import CallQualityIndicator from '../../components/CallQualityIndicator'
import { ENV_CONSTANTS, hasAccessFiles } from '../../utils/Utils'
import CallNotification from '../../components/CallNotification/CallNotification'
import { toggleFullScreen } from '../../redux/appSlice'
import DeviceNotReadyHandler from '../../components/DeviceNotReadyHandler'
import RoomChatDrawer from './RoomChat/RoomChatDrawer'
import { RoomInterface } from '../room/RoomInterface'
import ManageParticipants from '../../components/RoomManage/ManageParticipants/ManageParticipants'
import { useToastContext } from '../../components/Toast/ToastContext'
import { setShowModalConnectionError } from './redux/meSlice'
import { UseCallHideItemsOnMouseStop } from '../../utils/hooks/UseCallHideItemsOnMouseStop'

function Room() {
  const { t } = useTranslation('room')
  UseCallHideItemsOnMouseStop()
  const { roomId } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const navigate = useNavigate()

  const roomSelector = (state: RootState) => state.room
  const room = useSelector(roomSelector)
  const peers = useSelector((state: RootState) => state.room.peers)
  const email = useSelector((state: RootState) => state.auth.email)

  const accessToken = useSelector((state: RootState) => state.room.accessToken)
  const encryptKey = room?.decrypt_key
  const fetchAccessTokenStatus = useSelector(
    (state: RootState) => state.room.fetchAccessTokenStatus,
  )

  const username = useSelector(
    (state: RootState) => state.invite.email || state.auth.email,
  )
  const webcamActive = useSelector((state: RootState) => state.me.webcamActive)
  const micActive = useSelector((state: RootState) => state.me.micActive)
  const bokehEffectActive = useSelector((state: RootState) => state.me.bokehEffectActive)
  const notifyMissingParticipantsStatus = useSelector(
    (state: RootState) => state.rooms.notifyMissingParticipantsStatus,
  )
  const showModalConnectionError = useSelector(
    (state: RootState) => state.me.showModalConnectionError,
  )

  const roomClient = useRef(
    new RoomClient({
      roomId,
      dispatch,
      peerId: username,
      handlerName: null,
      useSimulcast: false,
      useSharingSimulcast: false,
      forceTcp: false,
      produce: true,
      consume: true,
      forceH264: false,
      forceVP9: false,
      svc: undefined,
      datachannel: true,
    }),
  )

  const me = useSelector((state: RootState) => state.me)
  const disconnectDueToMultipleConnections = useSelector(
    (state: RootState) => state.room.disconnectDueToMultipleConnections,
  )

  useEffect(() => {
    if (room.state == 'closed' && roomId) {
      dispatch(fetchAccessToken({ roomId }))
    }
  }, [room.state])

  useEffect(() => {
    if (accessToken) {
      roomClient.current.connect(accessToken)
      dispatch(clearAccessToken())
    }
  }, [accessToken])

  useEffect(() => {
    if (disconnectDueToMultipleConnections) {
      ToastOpen({
        message: t(
          'Disconnecting from the video call because you are connected to the call through another browser.',
        ),
        type: 'warning',
      })
      navigate('/upcoming-meetings')
    }
  }, [disconnectDueToMultipleConnections])

  useAttemptsListener(
    [
      [
        notifyMissingParticipantsStatus,
        {
          success: () =>
            ToastOpen({
              message: t('Missing participants were notified by email.'),
              type: 'success',
            }),
          NO_ORGANISATION: () =>
            ToastOpen({
              message: t(
                'Your account has been disabled because you are not belong to any organizations.',
              ),
              type: 'warning',
            }),
          error: () =>
            ToastOpen({
              message: t('An error occurred when notifying missing attendees.'),
              type: 'error',
            }),
        },
      ],
      [
        fetchAccessTokenStatus,
        {
          error: () => {
            if (room.state === 'closed') {
              ToastOpen({
                message: t('The meeting host has ended your session.'),
                duration: 30,
                type: 'warning',
              })
              navigate('/')
            }
          },
        },
      ],
    ],
    () => {
      dispatch(idleNotifyMissingParticipantsStatus())

      window.removeEventListener('show-info', showInfo)
      roomClient.current.close()
      dispatch(clearInvite())
      dispatch(clearMeeting())
    },
    () => {
      if (roomId) {
        dispatch(fetchAccessToken({ roomId }))
      }
      window.addEventListener('show-info', showInfo)
    },
  )

  /**
   * Allows to send a remind email to users who are not yet in the call
   */
  function onNotifyWaitingParticipants() {
    if (roomId) {
      dispatch(notifyMissingParticipants({ roomId }))
    }
  }

  function showInfo() {
    dispatch(setShowInfo(true))
  }

  function join() {
    roomClient.current.join(webcamActive, micActive)
  }

  /**
   *
   * @param value
   */
  async function changeCamera(value: string) {
    if (value && value != room.webcam) {
      await roomClient.current.changeWebcamDevice(value)
    }
  }

  /**
   *
   * @param value
   */
  async function changeMic(value: string) {
    if (value && value != room.mic) {
      await roomClient.current.changeMicDevice(value)
    }
  }

  /**
   *
   * @param value
   */
  async function changeSpeaker(value: string) {
    if (value && value != room.speakerId) {
      await roomClient.current.changeSpeaker(value)
    }
  }

  function refreshDevices() {
    roomClient.current.startWebcamDevice(bokehEffectActive)
    roomClient.current.startMicDevice()
    roomClient.current.updateSpeakerDevices()
  }

  const [documentDrawerOpen, setDocumentDrawerOpen] = useState(false)
  const [messagesDrawerOpen, setMessagesDrawerOpen] = useState(false)
  const [participantsDrawerOpen, setParticipantsDrawerOpen] = useState(false)
  const [settingDrawerOpen, setSettingDrawerOpen] = useState(false)

  const connectedPeersEmails = Object.keys(peers)
  const waitingInvitedEmails = room.waitingInvited.map((i) => i.email)
  const otherParticipants =
    room?.participants?.filter(
      (p) =>
        !connectedPeersEmails.includes(p.email) &&
        !waitingInvitedEmails.includes(p.email),
    ) || []
  const otherInvited =
    room?.invited?.filter(
      (i) =>
        !connectedPeersEmails.includes(i.email) &&
        !waitingInvitedEmails.includes(i.email),
    ) || []
  const waitingCount =
    room.waitingInvited.length +
    (otherParticipants?.length || 0) +
    (otherInvited?.length || 0)

  /**
   *
   */
  function openDrawerParticipants() {
    setDocumentDrawerOpen(false)
    setMessagesDrawerOpen(false)
    setSettingDrawerOpen(false)
    setParticipantsDrawerOpen(!participantsDrawerOpen)
  }

  /**
   *
   */
  function openDrawerMessages() {
    setDocumentDrawerOpen(false)
    setParticipantsDrawerOpen(false)
    setSettingDrawerOpen(false)
    setMessagesDrawerOpen(!messagesDrawerOpen)
  }

  /**
   *
   */
  function openDrawerDocuments() {
    setMessagesDrawerOpen(false)
    setParticipantsDrawerOpen(false)
    setSettingDrawerOpen(false)
    setDocumentDrawerOpen(!documentDrawerOpen)
  }

  /**
   *
   */
  function openDrawerSettings() {
    setMessagesDrawerOpen(false)
    setParticipantsDrawerOpen(false)
    setDocumentDrawerOpen(false)
    setSettingDrawerOpen(!settingDrawerOpen)
  }

  const isInAntechamber: boolean =
    room.state === 'new' || room.state === 'connecting' || room.state === 'connected'

  return (
    <>
      {room.state === 'closed' && <div className="closed" />}
      {isInAntechamber && (
        <Antechamber
          roomClient={roomClient}
          onJoin={join}
          onDocumentClick={() => setDocumentDrawerOpen(true)}
        />
      )}
      {room.state === 'joined' && (
        <>
          <div
            className={classNames({
              room_page: true,
              'room_page--drawer-opened':
                documentDrawerOpen ||
                messagesDrawerOpen ||
                participantsDrawerOpen ||
                settingDrawerOpen,
            })}
            onDoubleClick={() => dispatch(toggleFullScreen())}
          >
            <Peers roomClient={roomClient} />
            <CallControls
              name={room.name}
              roomClient={roomClient}
              documentDrawerOpen={documentDrawerOpen}
              messagesDrawerOpen={messagesDrawerOpen}
              participantsDrawerOpen={participantsDrawerOpen}
              settingDrawerOpen={settingDrawerOpen}
              encryptKey={encryptKey}
              setOpenDrawerDocuments={openDrawerDocuments}
              setOpenDrawerMessages={openDrawerMessages}
              setOpenDrawerParticipants={openDrawerParticipants}
              setOpenDrawerSettings={openDrawerSettings}
            />
          </div>

          {encryptKey && (
            <Drawer
              title={t('Documents', { ns: 'common' })}
              placement="right"
              onClose={() => setDocumentDrawerOpen(false)}
              open={documentDrawerOpen}
              className="documents-drawer room-drawer"
              mask={false}
            >
              <DocumentsUpload
                disabled={
                  !(
                    room?.hasAccessFiles &&
                    (room?.creator?.email === email ||
                      hasAccessFiles(room as RoomInterface))
                  )
                }
                room={room}
                immediateSave={true}
              />
              <h4 className="uppercase mt-1rem mb-1rem">
                {t('Available files', { ns: 'common' })}
              </h4>
              <div className="documents_list-container">
                <DocumentsList
                  disabled={
                    !(
                      room.creator?.email === email ||
                      hasAccessFiles(room as RoomInterface)
                    )
                  }
                  room={room}
                  immediateSave={true}
                />
              </div>
            </Drawer>
          )}

          {room.id && (
            <RoomChatDrawer
              currentSquadId={room.id}
              messagesDrawerOpen={messagesDrawerOpen}
              onMessagesClose={() => setMessagesDrawerOpen(false)}
            />
          )}

          <Drawer
            title={t('Participants')}
            placement="right"
            onClose={() => setParticipantsDrawerOpen(false)}
            open={participantsDrawerOpen}
            className="participants-drawer room-drawer"
            mask={false}
          >
            <Collapse
              defaultActiveKey={['1', '2']}
              ghost
              expandIconPosition="end"
              expandIcon={(panelProps) => {
                return (
                  <BsChevronDown
                    className={classNames('panel-collapse-icon', {
                      'panel-collapse-icon--active': panelProps.isActive,
                    })}
                  />
                )
              }}
            >
              <Collapse.Panel
                header={<h4 className="uppercase">{t('Add participant')}</h4>}
                key="1"
              >
                <ManageParticipants
                  vertical={true}
                  room={room}
                  disabled={false}
                  addParticipant={addParticipant}
                  addInvited={addInvited}
                  deleteParticipant={deleteParticipant}
                  deleteInvited={deleteInvited}
                  updateParticipantRole={updateParticipantRole}
                  updateInvitedRole={updateInvitedRole}
                />
              </Collapse.Panel>

              <Collapse.Panel
                header={
                  <div className="d-flex d-flex-between d-flex-middle">
                    <h4 className="uppercase">{t('Waiting participants')}</h4>
                    {room.waitingInvited.length > 0 && (
                      <span className="participants-drawer-collapse-header-badge participants-drawer-collapse-header-badge--waiting">
                        {room.waitingInvited.length}
                      </span>
                    )}
                  </div>
                }
                key="2"
              >
                {room.waitingInvited.map((w) => (
                  <div className="room-participant-item" key={w.email}>
                    <div className="room-participant-item-body">
                      <div className="room-participant-item-body-email">{w.email}</div>
                      <div className="room-participant-item-body-role">{t(w.role)}</div>
                    </div>
                    <div className="room-participant-item-actions">
                      <Tooltip title={t('Allow')}>
                        <BsFillCheckSquareFill
                          role="button"
                          className="room-participant-item-actions-buttons success-color"
                          onClick={() => roomClient.current.allowInvited(w.email)}
                        />
                      </Tooltip>

                      <Tooltip title={t('Prohibit')}>
                        <BsFillXSquareFill
                          role="button"
                          color="#ff4d4f"
                          className="room-participant-item-actions-buttons ml-1rem error-color"
                          onClick={() => roomClient.current.prohibitInvited(w.email)}
                        />
                      </Tooltip>
                    </div>
                  </div>
                ))}

                {otherParticipants?.map((p) => (
                  <RoomParticipant
                    key={p.email}
                    firstName={p.firstName}
                    lastName={p.lastName}
                    email={p.email}
                    role={p.role}
                    networkTrouble={room.networkTroubleUsers.includes(p.email)}
                  />
                ))}
                {otherInvited?.map((i) => (
                  <RoomParticipant
                    key={i.email}
                    email={i.email}
                    firstName={i.firstName}
                    lastName={i.lastName}
                    role={i.role}
                    networkTrouble={room.networkTroubleUsers.includes(i.email)}
                  />
                ))}

                {waitingCount > 0 && (
                  <Button
                    className="full-width-btn mb-1rem"
                    type="primary"
                    size="middle"
                    onClick={onNotifyWaitingParticipants}
                  >
                    <AiOutlineMail className="mr-05rem" /> {t('Notify')}
                  </Button>
                )}

                {waitingCount === 0 && (
                  <div className="waiting-empty">{t('No participants waiting')}</div>
                )}
              </Collapse.Panel>

              <Collapse.Panel
                header={
                  <div className="d-flex d-flex-between d-flex-middle">
                    <h4 className="uppercase">{t('Connected participants')}</h4>
                    {connectedPeersEmails.length > 0 && (
                      <span className="participants-drawer-collapse-header-badge participants-drawer-collapse-header-badge--connected">
                        {connectedPeersEmails.length}
                      </span>
                    )}
                  </div>
                }
                key="3"
              >
                {connectedPeersEmails.map((email) => (
                  <RoomParticipant
                    key={email}
                    email={email}
                    role={peers[email].role}
                    networkTrouble={room.networkTroubleUsers.includes(email)}
                  />
                ))}
                {connectedPeersEmails.length <= 0 && (
                  <div className="waiting-empty">{t('No connected participants')}</div>
                )}
              </Collapse.Panel>
            </Collapse>
          </Drawer>

          <Drawer
            title={t('Settings')}
            placement="right"
            onClose={() => setSettingDrawerOpen(false)}
            open={settingDrawerOpen}
            className="setting-drawer room-drawer"
            mask={false}
          >
            <Collapse
              defaultActiveKey={['1', '2', '3']}
              ghost
              expandIconPosition="end"
              expandIcon={(panelProps) => {
                return (
                  <BsChevronDown
                    className={classNames('panel-collapse-icon', {
                      'panel-collapse-icon--active': panelProps.isActive,
                    })}
                  />
                )
              }}
            >
              <Collapse.Panel
                header={<h4 className="uppercase">{t('Peripheral devices')}</h4>}
                key="1"
              >
                <div>{t('Camera', { ns: 'common' })}</div>

                <DeviceNotReadyHandler
                  deviceStatus={me.webcamStatus}
                  messages={{
                    ASKING_PERMISSIONS: t('WEBCAM_STATUS_ASKING_PERMISSIONS', {
                      ns: 'roomDevicesConfiguration',
                    }),
                    FAILED: t('WEBCAM_STATUS_FAILED', { ns: 'roomDevicesConfiguration' }),
                    NOT_ALLOWED: t('WEBCAM_STATUS_NOT_ALLOWED', {
                      ns: 'roomDevicesConfiguration',
                    }),
                    NOT_FOUND: t('WEBCAM_STATUS_NOT_FOUND', {
                      ns: 'roomDevicesConfiguration',
                    }),
                    STOPPED: t('WEBCAM_STATUS_STOPPED', {
                      ns: 'roomDevicesConfiguration',
                    }),
                  }}
                >
                  <Select
                    className="mb-05rem"
                    value={room.webcam}
                    style={{ width: '100%' }}
                    onChange={changeCamera}
                    options={room.webcams}
                  />
                </DeviceNotReadyHandler>
                <div>{t('Microphone', { ns: 'common' })}</div>
                <DeviceNotReadyHandler
                  deviceStatus={me.micStatus}
                  messages={{
                    ASKING_PERMISSIONS: t('MIC_STATUS_ASKING_PERMISSIONS', {
                      ns: 'roomDevicesConfiguration',
                    }),
                    FAILED: t('MIC_STATUS_FAILED', { ns: 'roomDevicesConfiguration' }),
                    NOT_ALLOWED: t('MIC_STATUS_NOT_ALLOWED', {
                      ns: 'roomDevicesConfiguration',
                    }),
                    NOT_FOUND: t('MIC_STATUS_NOT_FOUND', {
                      ns: 'roomDevicesConfiguration',
                    }),
                    STOPPED: t('MIC_STATUS_STOPPED', { ns: 'roomDevicesConfiguration' }),
                  }}
                >
                  <Select
                    className="mb-05rem"
                    value={room.mic}
                    style={{ width: '100%' }}
                    onChange={changeMic}
                    options={room.mics}
                  />
                </DeviceNotReadyHandler>

                <div>{t('Speaker', { ns: 'common' })}</div>
                {me.speakerInProgress ? (
                  <Alert
                    message={t('Please wait. Device detection in progress.', {
                      ns: 'roomDevicesConfiguration',
                    })}
                    type="warning"
                    icon={<Spin />}
                    showIcon={true}
                  />
                ) : room.speakers && room.speakers.length > 0 ? (
                  <Select
                    className="mb-05rem"
                    value={room.speakerId}
                    style={{ width: '100%' }}
                    onChange={changeSpeaker}
                    options={room.speakers}
                  />
                ) : (
                  <Alert
                    message={t('SPEAKERS_STATUS_NOT_FOUND', {
                      ns: 'roomDevicesConfiguration',
                    })}
                    type="error"
                  />
                )}
                <div
                  className="d-flex d-flex-center d-flex-middle refresh-container mb-05rem"
                  onClick={refreshDevices}
                >
                  <FiRefreshCcw size="1em" />
                  <span>{t('Refresh devices', { ns: 'common' })}</span>
                </div>
              </Collapse.Panel>
              <Collapse.Panel
                header={<h4 className="uppercase">{t('Interface languages')}</h4>}
                key="2"
              >
                <LanguageSwitch />
              </Collapse.Panel>
              <Collapse.Panel
                header={<h4 className="uppercase">{t('Call Quality')}</h4>}
                key="3"
              >
                <Row>
                  <Col span={6}>
                    <CallQualityIndicator />
                  </Col>
                  <Col span={18}>
                    <Tooltip
                      title={t(
                        'The received video on the side of the interlocutor may differ in quality depending on its connection',
                      )}
                    >
                      {t('Transmitted video quality')}
                    </Tooltip>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Drawer>
        </>
      )}
      <CallNotification roomClient={roomClient} />
      <Modal
        centered
        title={t('Connection error', { ns: 'common' })}
        open={showModalConnectionError}
        confirmLoading={false}
        footer={
          <div className="d-flex">
            <Button
              onClick={() => dispatch(setShowModalConnectionError(false))}
              className="mr-05rem"
            >
              {t('Close', { ns: 'common' })}
            </Button>
            <a href="/faq/network" target="_blank">
              <Button type="primary">{t('See', { ns: 'common' })}</Button>
            </a>
          </div>
        }
      >
        <p className="text-justify unwrapped-text">
          <Trans
            i18nKey="UNABLE_CONNECT_CALL"
            shouldUnescape={true}
            ns="room"
            values={{ applicationName: ENV_CONSTANTS['DEFAULT_APPLICATION_NAME'] }}
          />
          <br />
          {t(
            'Please ensure that your device has an active Internet connection and that all device and network security solutions have been configured to allow access to our servers.',
          )}
          <br />
          {t('For further information, please see the ', { ns: 'faq' })}
          <a href="/faq/network" target="_blank">
            {t('network configuration page', { ns: 'faq' })}
          </a>
          .
        </p>
      </Modal>
    </>
  )
}

export default Room
