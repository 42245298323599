import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  websocketChatListAction,
  websocketChatCreatedAction,
  websocketChatMessagesAction,
  websocketChatMessageReceivedAction,
  websocketChatUserAddedAction,
  websocketChatUserRemovedAction,
  websocketChatUsersUpdatedAction,
  websocketChatDetailsAction,
  websocketChatUpdatedAction,
  websocketChatArchivedAction,
  websocketChatUnarchivedAction,
  websocketChatMessageDeletedAction,
  websocketChatMessageEditedAction,
  websocketChatDetailsListAction,
  websocketOpenChatRequestAction,
  websocketChatUserTypingAction,
  webSocketChatReactionAdded,
  webSocketChatReactionDeleted,
  webSocketChatMessagesSeenAction,
} from '../WebsocketConnection/AppWebsocketConnection'
import _ from 'lodash'
import { UploadFile } from 'antd'
import {
  SearchGroupDTO,
  SearchUserDTO,
} from '../../../features/Organisation/OrganisationService'
import { OrganizationRole } from '../../../features/Organisation/OrganizationInterface'

export interface ChatReaction {
  userReactionId?: string
  reaction: string
  users: {
    email: string
    firstName: string
    lastName: string
    organizationRole: OrganizationRole
  }[]
}

export interface ChatMessage {
  id: string
  peerEmail: string
  peerFirstName?: string
  peerLastName?: string
  peerOrganizationRole?: OrganizationRole
  createdAt: string
  editedAt?: string
  deleted?: boolean
  text: string
  files: string
  repliedTo: string
  reactions: ChatReaction[]
}

export interface Chat {
  id: string
  title: string
  squadId: string
  fileEncryptionKey: string
  everybody: boolean
  peers: SearchUserDTO[]
  groups: SearchGroupDTO[]
  peerToPeer: boolean
  peer?: SearchUserDTO
  archived: boolean
}

interface DetailsChatActivity {
  [peerEmail: string]: {
    peerFirstName: string
    peerLastName: string
    seenMessagesAt: string
  }
}

export interface ChatDetails {
  lastMessage?: ChatMessage
  scrollPosition?: number
  unreadMessagesCount: number
  chatActivity: DetailsChatActivity
}

export interface CitationMessage {
  message: ChatMessage | undefined
}

export interface MessageInputDraft {
  messageToEdit?: ChatMessage | undefined
  messageToReply?: ChatMessage | undefined
  text?: string
  files?: UploadFile<any>[] | undefined
}

export interface ChatMessages {
  chunks: {
    [chunk: string]: ChatMessage[]
  } //history from server
  live: ChatMessage[] //when online gets from webSocket
}
export interface ChatState {
  chats: { [squadId: string]: Chat[] }
  chatsDetails: { [chatId: string]: ChatDetails }
  chatsMessages: {
    [chatId: string]: ChatMessages
  }
  currentChat?: {
    id: string
    scrolledToBottom: boolean
    uploadFile: boolean
    calling: boolean
  }
  chatToModify?: Chat
  messageInputDraft: {
    [chatId: string]: MessageInputDraft
  }
  typingUsers: {
    [chatId: string]: {
      [email: string]: {
        firstName: string
        lastName: string
        timestamp: number
      }
    }
  }
}

export const initialState: ChatState = {
  chats: {},
  chatsDetails: {},
  chatsMessages: {},
  messageInputDraft: {},
  typingUsers: {},
}

function getChunkIndex(chunks: { [chunk: string]: ChatMessage[] }, messageId: string) {
  for (const iChunk in chunks) {
    if (chunks[iChunk].some((message) => message.id === messageId)) return iChunk
  }
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setCurrentChatId(state, { payload }: PayloadAction<string | undefined>) {
      if (payload) {
        // const prevChatId = state.currentChat?.id
        // if (prevChatId && state.chatsDetails[prevChatId]) {
        //   state.chatsDetails[prevChatId].unreadMessagesCount = 0
        // }

        const unreadMessagesCount = state.chatsDetails[payload]?.unreadMessagesCount || 0

        state.currentChat = {
          id: payload,
          scrolledToBottom: state.chatsDetails[payload]?.scrollPosition
            ? false
            : unreadMessagesCount === 0,
          uploadFile: false,
          calling: false,
        }
      } else {
        state.currentChat = undefined
      }
    },
    saveChatScrollPosition(
      state,
      { payload }: PayloadAction<{ chatId: string; position: number }>,
    ) {
      if (state.chatsDetails[payload.chatId]) {
        state.chatsDetails[payload.chatId].scrollPosition = payload.position
      }
    },
    setChatToModify(state, { payload }: PayloadAction<Chat | undefined>) {
      state.chatToModify = payload
    },
    updateChatToModify(
      state,
      {
        payload,
      }: PayloadAction<{
        title: string
        users: SearchUserDTO[]
        groups: SearchGroupDTO[]
      }>,
    ) {
      if (state.chatToModify) {
        state.chatToModify.title = payload.title
        state.chatToModify.peers = payload.users
        state.chatToModify.groups = payload.groups
      }
    },
    markMessagesRead(state, { payload }: PayloadAction<string>) {
      if (state.chatsDetails[payload]) {
        state.chatsDetails[payload].unreadMessagesCount = 0
      }
    },
    setScrolledToBottom(state, { payload }: PayloadAction<boolean>) {
      if (state.currentChat) {
        state.currentChat.scrolledToBottom = payload
      }
    },
    setMessageInputDraftText(
      state,
      { payload }: PayloadAction<Pick<MessageInputDraft, 'text'> & { chatId: string }>,
    ) {
      //TODO: save draft on server side (allow user switch device)
      const { chatId, text } = payload
      state.messageInputDraft[chatId] = {
        ...state.messageInputDraft[chatId],
        text,
      }
    },
    setMessageInputDraftFiles(
      state,
      { payload }: PayloadAction<Pick<MessageInputDraft, 'files'> & { chatId: string }>,
    ) {
      const { chatId, files } = payload
      const _files = files && files.length > 0 ? files : undefined //if file has been added, and the removed files are equal to []
      state.messageInputDraft[chatId] = {
        ...state.messageInputDraft[chatId],
        files: _files,
      }
    },
    setMessageInputDraftReplyMsg(
      state,
      {
        payload,
      }: PayloadAction<Pick<MessageInputDraft, 'messageToReply'> & { chatId: string }>,
    ) {
      const { chatId, messageToReply } = payload
      state.messageInputDraft[chatId] = {
        ...state.messageInputDraft[chatId],
        messageToEdit: undefined,
        messageToReply,
      }
    },
    setMessageInputDraftEditMsg(
      state,
      {
        payload,
      }: PayloadAction<Pick<MessageInputDraft, 'messageToEdit'> & { chatId: string }>,
    ) {
      const { chatId, messageToEdit } = payload
      state.messageInputDraft[chatId] = {
        ...state.messageInputDraft[chatId],
        messageToReply: undefined,
        messageToEdit,
      }
    },
    resetMessageInputDraft(state, { payload }: PayloadAction<{ chatId: string }>) {
      const { chatId } = payload
      delete state.messageInputDraft[chatId]
    },
    setCalling(state, { payload }: PayloadAction<boolean>) {
      if (state.currentChat) {
        state.currentChat.calling = payload
      }
    },
    updateTypingUsers(state) {
      console.log('updateTypingUsers')
      for (const chatId of Object.keys(state.typingUsers)) {
        for (const email of Object.keys(state.typingUsers[chatId])) {
          if (Date.now() - state.typingUsers[chatId][email].timestamp > 5000) {
            delete state.typingUsers[chatId][email]
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(websocketChatListAction, (state, { payload }) => {
        state.chats[payload.squadId] = payload.chatList.map((chat) => {
          if (chat.peerToPeer) {
            chat.peer = chat.peers.find((peer) => peer.email != payload.me)
          }
          return chat
        })
      })
      .addCase(websocketChatDetailsListAction, (state, { payload }) => {
        for (const chatDetails of payload.details) {
          state.chatsDetails[chatDetails.chatId] = {
            lastMessage: chatDetails.lastMessage,
            unreadMessagesCount: chatDetails.unreadMessagesCount,
            chatActivity: chatDetails.chatActivity.reduce((acc, activity) => {
              acc[activity.peerEmail] = {
                peerFirstName: activity.peerFirstName,
                peerLastName: activity.peerLastName,
                seenMessagesAt: activity.seenMessagesAt,
              }
              return acc
            }, {} as DetailsChatActivity),
          }
        }
      })
      .addCase(websocketChatDetailsAction, (state, { payload }) => {
        state.chatsDetails[payload.chatId] = {
          lastMessage: payload.lastMessage,
          unreadMessagesCount: payload.unreadMessagesCount,
          chatActivity: payload.chatActivity.reduce((acc, activity) => {
            acc[activity.peerEmail] = {
              peerFirstName: activity.peerEmail,
              peerLastName: activity.peerLastName,
              seenMessagesAt: activity.seenMessagesAt,
            }
            return acc
          }, {} as DetailsChatActivity),
        }
      })
      .addCase(webSocketChatMessagesSeenAction, (state, { payload }) => {
        const peerActivity =
          state.chatsDetails[payload.chatId]?.chatActivity[payload.peerEmail]

        if (peerActivity) {
          state.chatsDetails[payload.chatId].chatActivity[payload.peerEmail] = {
            peerFirstName: payload.peerFirstName,
            peerLastName: payload.peerLastName,
            seenMessagesAt: payload.seenMessagesAt,
          }
        }
      })
      .addCase(websocketChatCreatedAction, (state, { payload }) => {
        const newChat = payload.chat
        if (newChat.peerToPeer) {
          newChat.peer = newChat.peers.find((peer) => peer.email != payload.me)
        }

        if (!state.chats[newChat.squadId]) {
          state.chats[newChat.squadId] = []
          state.chatsDetails[newChat.id] = {
            unreadMessagesCount: 0,
            chatActivity: {},
          }
        }

        const chatsArray = state.chats[payload.chat.squadId]
        if (!chatsArray.some((c) => c.id === newChat.id)) {
          chatsArray.push(newChat)
          chatsArray.sort((a, b) => a.title.localeCompare(b.title))
        }
      })
      .addCase(websocketOpenChatRequestAction, (state, { payload }) => {
        state.currentChat = {
          id: payload.chatId,
          scrolledToBottom: true,
          uploadFile: false,
          calling: false,
        }
      })
      .addCase(websocketChatUpdatedAction, (state, { payload }) => {
        for (const squadId in state.chats) {
          const chatToUpdate = state.chats[squadId].find(
            (chat) => chat.id === payload.chatId,
          )

          if (chatToUpdate) {
            chatToUpdate.title = payload.title

            const sortedChatsArray = [
              state.chats[squadId][0],
              ...state.chats[squadId]
                .slice(1)
                .sort((a, b) => a.title.localeCompare(b.title)),
            ]
            state.chats[squadId] = sortedChatsArray
            return
          }
        }
      })
      .addCase(websocketChatArchivedAction, (state, { payload }) => {
        for (const squadId in state.chats) {
          // TODO: Beware of race conditions !
          if (state.chats[squadId].some((c) => c.id != payload.chatId)) {
            state.chats[squadId] = state.chats[squadId].filter(
              (c) => c.id != payload.chatId,
            )

            const everybodyChat = state.chats[squadId].find((c) => c.everybody)?.id
            if (everybodyChat) {
              const unreadMessagesCount =
                state.chatsDetails[everybodyChat]?.unreadMessagesCount || 0

              state.currentChat = {
                id: everybodyChat,
                scrolledToBottom: unreadMessagesCount === 0,
                uploadFile: false,
                calling: false,
              }
            } else {
              state.currentChat = undefined
            }
          }
        }
      })

      .addCase(websocketChatUnarchivedAction, (state, { payload }) => {
        for (const squadId in state.chats) {
          state.chats[squadId] = [...state.chats[squadId], payload.chat]
        }
        const unreadMessagesCount =
          state.chatsDetails[payload.chat.id]?.unreadMessagesCount || 0
        state.currentChat = {
          id: payload.chat.id,
          scrolledToBottom: unreadMessagesCount === 0,
          uploadFile: false,
          calling: false,
        }
      })

      .addCase(websocketChatMessagesAction, (state, { payload }) => {
        if (!state.chatsMessages[payload.chatId] || payload.newestChunk) {
          state.chatsMessages[payload.chatId] = { chunks: {}, live: [] }
        }
        state.chatsMessages[payload.chatId].chunks[payload.chunk] = payload.messages
        if (!state.chatsDetails[payload.chatId]) {
          state.chatsDetails[payload.chatId] = {
            unreadMessagesCount: 0,
            chatActivity: {},
          }
        }
      })
      .addCase(websocketChatMessageReceivedAction, (state, { payload }) => {
        if (!state.chatsMessages[payload.chatId]) {
          state.chatsMessages[payload.chatId] = { chunks: {}, live: [] }
        }
        state.chatsMessages[payload.chatId].live.push(payload.message)

        if (!state.chatsDetails[payload.chatId]) {
          state.chatsDetails[payload.chatId] = {
            unreadMessagesCount: 0,
            chatActivity: {},
          }
        }
        state.chatsDetails[payload.chatId].lastMessage = payload.message

        if (
          payload.me !== payload.message.peerEmail &&
          (state.currentChat?.id != payload.chatId ||
            (state.currentChat && !state.currentChat.scrolledToBottom))
        ) {
          state.chatsDetails[payload.chatId].unreadMessagesCount++
        }
      })
      .addCase(websocketChatMessageDeletedAction, (state, { payload }) => {
        const { chatId, messageId, deleted } = payload

        const markDeletedMessage = (chatMessages: ChatMessage[]) => {
          const msgToReplaceIndex = chatMessages?.findIndex((msg) => msg.id === messageId)
          if (msgToReplaceIndex === -1) return
          chatMessages[msgToReplaceIndex] = {
            ...chatMessages[msgToReplaceIndex],
            deleted: deleted,
          }
        }

        markDeletedMessage(state.chatsMessages[chatId].live)

        const chunkIndex = getChunkIndex(state.chatsMessages[chatId].chunks, messageId)
        if (chunkIndex) markDeletedMessage(state.chatsMessages[chatId].chunks[chunkIndex])

        const lastMessage = state.chatsDetails[chatId].lastMessage
        if (messageId === lastMessage?.id) {
          state.chatsDetails[chatId].lastMessage = { ...lastMessage, deleted: deleted }
        }
      })
      .addCase(websocketChatMessageEditedAction, (state, { payload }) => {
        const { chatId, message } = payload
        const replaceMessage = (chatMessages: ChatMessage[]) => {
          const msgToReplaceIndex = chatMessages?.findIndex(
            (msg) => msg.id === message.id,
          )
          if (msgToReplaceIndex === -1) return

          const lastMessage = state.chatsDetails[chatId].lastMessage
          if (message.id === lastMessage?.id) {
            state.chatsDetails[chatId].lastMessage = message
          }

          chatMessages[msgToReplaceIndex] = {
            ...chatMessages[msgToReplaceIndex],
            editedAt: message.editedAt,
            text: message.text,
            files: message.files,
            reactions: message.reactions,
          }
        }
        replaceMessage(state.chatsMessages[chatId].live)

        const chunkIndex = getChunkIndex(state.chatsMessages[chatId].chunks, message.id)
        if (chunkIndex) replaceMessage(state.chatsMessages[chatId].chunks[chunkIndex])
      })
      .addCase(websocketChatUserTypingAction, (state, { payload }) => {
        if (payload.user.email === payload.me) {
          return
        }

        if (payload.typing) {
          if (!state.typingUsers[payload.chatId]) {
            state.typingUsers[payload.chatId] = {}
          }

          if (state.typingUsers[payload.chatId][payload.user.email]) {
            state.typingUsers[payload.chatId][payload.user.email].timestamp = Date.now()
          } else {
            state.typingUsers[payload.chatId][payload.user.email] = {
              firstName: payload.user.firstName,
              lastName: payload.user.lastName,
              timestamp: Date.now(),
            }
          }
        } else {
          if (
            state.typingUsers[payload.chatId] &&
            state.typingUsers[payload.chatId][payload.user.email]
          ) {
            delete state.typingUsers[payload.chatId][payload.user.email]
          }
        }
      })
      .addCase(websocketChatUserAddedAction, (state, { payload }) => {
        const newChat = payload.chat
        if (!state.chats[newChat.squadId]) {
          state.chats[newChat.squadId] = []
          state.chatsDetails[newChat.id] = {
            unreadMessagesCount: 0,
            chatActivity: {},
          }
        }

        const chatsArray = state.chats[payload.chat.squadId]
        if (!chatsArray.some((c) => c.id === newChat.id)) {
          chatsArray.push(newChat)
          chatsArray.sort((a, b) => a.title.localeCompare(b.title))
        }
      })
      .addCase(websocketChatUserRemovedAction, (state, { payload }) => {
        if (state.chats[payload.squadId]) {
          state.chats[payload.squadId] = state.chats[payload.squadId].filter(
            (chat) => chat.id !== payload.chatId,
          )
        }
        if (state.currentChat?.id === payload.chatId) {
          const everybodyChat = state.chats[payload.squadId].find((c) => c.everybody)?.id
          if (everybodyChat) {
            const unreadMessagesCount =
              state.chatsDetails[everybodyChat]?.unreadMessagesCount || 0

            state.currentChat = {
              id: everybodyChat,
              scrolledToBottom: unreadMessagesCount === 0,
              uploadFile: false,
              calling: false,
            }
          } else {
            state.currentChat = undefined
          }
        }
      })
      .addCase(websocketChatUsersUpdatedAction, (state, { payload }) => {
        if (state.chats[payload.squadId]) {
          const currentChats = state.chats[payload.squadId]

          const chatIndex = currentChats.findIndex((chat) => chat.id === payload.chat.id)

          if (chatIndex !== -1) {
            currentChats[chatIndex] = {
              ...currentChats[chatIndex],
              ...payload.chat,
            }
          }
        }
      })
      .addCase(webSocketChatReactionAdded, (state, { payload }) => {
        const { reaction, reactionId, messageId, chatId, author } = payload
        const fromMe = payload.me === author.email

        const replaceMessage = (chatMessages: ChatMessage[]) => {
          const msgToReplaceIndex = chatMessages?.findIndex((msg) => msg.id === messageId)
          if (msgToReplaceIndex === -1) return

          const formerReaction = chatMessages[msgToReplaceIndex].reactions.find(
            (r) => r.reaction === reaction,
          )
          const formerUserList = formerReaction?.users || []
          const filteredReactions = {
            reaction: reaction,
            userReactionId: fromMe ? reactionId : formerReaction?.userReactionId,
            users: [...formerUserList, author],
          }

          const reactionIndex = chatMessages[msgToReplaceIndex].reactions.findIndex(
            (r) => r.reaction === filteredReactions.reaction,
          )
          if (reactionIndex === -1) {
            chatMessages[msgToReplaceIndex].reactions.push(filteredReactions)
          } else {
            chatMessages[msgToReplaceIndex].reactions[reactionIndex] = filteredReactions
          }
        }
        replaceMessage(state.chatsMessages[chatId].live)

        const chunkIndex = getChunkIndex(state.chatsMessages[chatId].chunks, messageId)
        if (chunkIndex) replaceMessage(state.chatsMessages[chatId].chunks[chunkIndex])
      })
      .addCase(webSocketChatReactionDeleted, (state, { payload }) => {
        const { reaction, messageId, chatId, author } = payload
        const fromMe = payload.me === author.email

        const replaceMessage = (chatMessages: ChatMessage[]) => {
          const msgToReplaceIndex = chatMessages?.findIndex((msg) => msg.id === messageId)
          if (msgToReplaceIndex === -1) return

          const formerReaction = chatMessages[msgToReplaceIndex].reactions.find(
            (r) => r.reaction === reaction,
          )
          const formerUserList = formerReaction?.users || []
          const filteredReactions = {
            reaction: reaction,
            userReactionId: fromMe ? undefined : formerReaction?.userReactionId,
            users: formerUserList.filter((u) => u.email !== author.email),
          }

          const reactionIndex = chatMessages[msgToReplaceIndex].reactions.findIndex(
            (r) => r.reaction === filteredReactions.reaction,
          )
          if (reactionIndex !== -1) {
            if (filteredReactions.users.length > 0) {
              chatMessages[msgToReplaceIndex].reactions[reactionIndex] = filteredReactions
            } else {
              chatMessages[msgToReplaceIndex].reactions = chatMessages[
                msgToReplaceIndex
              ].reactions.filter((r) => r.reaction !== filteredReactions.reaction)
            }
          }
        }
        replaceMessage(state.chatsMessages[chatId].live)

        const chunkIndex = getChunkIndex(state.chatsMessages[chatId].chunks, messageId)
        if (chunkIndex) replaceMessage(state.chatsMessages[chatId].chunks[chunkIndex])
      })
  },
})

export const {
  setCurrentChatId,
  setChatToModify,
  updateChatToModify,
  markMessagesRead,
  setScrolledToBottom,
  setMessageInputDraftText,
  setMessageInputDraftFiles,
  setMessageInputDraftReplyMsg,
  setMessageInputDraftEditMsg,
  resetMessageInputDraft,
  // updateLastMessage,
  saveChatScrollPosition,
  setCalling,
  updateTypingUsers,
} = chatSlice.actions

export default chatSlice.reducer
